import React, { useEffect, useState } from "react";
import InputComponent from "../components/inputComponent";
import Button from "../components/button";
import {
  handleGetCustomerSupport,
  handleADDCustomerSupport,
} from "../networks/adminApi";
import { FaBullseye } from "react-icons/fa";
import { toast } from "react-toastify";

const CustomerSupport = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [email, setEmail] = useState("");
  const [edit, setEdit] = useState(false);

  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [whatsappNoError, setWhatsappNoError] = useState("");
  const [emailError, setEmailError] = useState("");

  const validation = () => {
    let valid = true;
    const emailRegex = /^[^\s@]+@(?:hotmail|gmail|yahoo|com)\.[^\s@]+$/i;
    if (phoneNumber === "") {
      valid = false;
      setPhoneNumberError("*Please enter phone");
    } else {
      setPhoneNumberError("");
    }

    if (whatsappNo === "") {
      valid = false;
      setWhatsappNoError("*Please enter whatsapp number");
    } else {
      setWhatsappNoError("");
    }

    if (email.trim() === "") {
      valid = false;
      setEmailError("*Please enter email");
    } else if (!emailRegex.test(email)) {
      valid = false;
      setEmailError("*Please enter a valid email");
    } else {
      setEmailError("");
    }
    return valid;
  };

  const fetchCustomerSupport = async () => {
    const res = await handleGetCustomerSupport();
    console.log(res, "Contact SUUUU");
    if (res.data.length > 0) {
      setEdit(true);
      setEmail(res?.data[0]?.email);
      setPhoneNumber(res?.data[0]?.phoneNumber);
      setWhatsappNo(res?.data[0]?.whatsappNumber);
    }
  };

  useEffect(() => {
    fetchCustomerSupport();
  }, []);

  const addSupport = async () => {
    console.log("CLicked");
    if (validation()) {
      console.log("OOOO");
      const res = await handleADDCustomerSupport({
        email: email,
        phoneNumber: phoneNumber,
        whatsappNumber: whatsappNo,
      });
      toast.success(res?.message);
      fetchCustomerSupport();
    }
  };

  return (
    <div className="ml-[20px] pt-[20px]">
      <InputComponent
        lable={"Mobile No"}
        placeholder={"Please enter your mobile No."}
        value={phoneNumber}
        onChange={setPhoneNumber}
        width={"w-[80%]"}
        type="number"
        maxLength={"10"}
      />
      {phoneNumberError && <div className="error">{phoneNumberError}</div>}
      <InputComponent
        lable={"Whatsapp No"}
        placeholder={"Please enter your whatsapp No."}
        value={whatsappNo}
        onChange={setWhatsappNo}
        width={"w-[80%]"}
        type="number"
        maxLength={"10"}
      />
      {whatsappNoError && <div className="error">{whatsappNoError}</div>}
      <InputComponent
        lable={"Eamil"}
        placeholder={"Please enter your mobile No."}
        value={email}
        onChange={setEmail}
        width={"w-[80%]"}
      />
      {emailError && <div className="error">{emailError}</div>}

      <div className="flex flex-row mt-[30px]">
        <Button
          lable={`${edit ? "Edit DEtails" : "Add Details"}`}
          onClick={addSupport}
        />
      </div>
    </div>
  );
};

export default CustomerSupport;

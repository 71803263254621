import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleGetTransactionList } from "../networks/adminApi";
import Table from "../components/table";
import Pagination from "../components/pagination";
import Loading from "./loader/Loading";
import LinkComponent from "../components/linkComponent";

const TimeFormat = ({ time }) => {
  const date = new Date(time);

  const formattedDate = date.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return <div>{formattedDate}</div>;
};

const TransactionList = () => {
  const [transactionList, setTransactionList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchTransactionData = async () => {
    setLoading(true);
    try {
      const res = await handleGetTransactionList(currentPage, searchTerm);
      console.log(res?.data?.transactions, "TransactionList");
      setTransactionList(res?.data?.transactions);
      setTotalPages(res?.data?.totalPages);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTransactionData();
  }, [currentPage, searchTerm]);

  const handleViewDetailClick = (transactionId) => {
    // Replace with your desired action, e.g., navigation to a detail page
    navigate(`/Transactionlistbyid/${transactionId}`);
  };

  const filteredtransactionList = transactionList?.filter((item) => {
    const search = searchTerm.toLowerCase();
    return (
      String(item?.id)?.toLowerCase().includes(search) ||
      String(item?.type)?.toLowerCase().includes(search) ||
      String(item?.userId?.name)?.toLowerCase().includes(search) ||
      String(item?.amount)?.toLowerCase().includes(search) ||
      String(item?.name)?.toLowerCase().includes(search)
    );
  });

  console.log(transactionList, "JJJJJ", filteredtransactionList, "DATAAATATAT");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Type", accessor: "type" },
    { Header: "Game Id", accessor: "gameId" },
    { Header: "Name", accessor: "name" },
    { Header: "Amount", accessor: "amount" },
    // { Header: "Deposit", accessor: "deposit" },
    // { Header: "Transaction Status", accessor: "transactionstatus" },

    { Header: "View Detail", accessor: "viewdetail" },
    { Header: "Match list", accessor: "matchlist" },
    { Header: "Status", accessor: "status" },
    { Header: "Time", accessor: "time" },
  ];

  const transactionData = [];

  filteredtransactionList &&
    filteredtransactionList.forEach((item, index) => {
      transactionData.push({
        _id: item._id,
        s_no: item.s_no, // Assuming s_no is the index in the array
        type: item.type,
        name: item.userId?.name,
        amount: item.amount,
        gameId: item?.challenge?.appRoomId || item?.gameEvent?.uid || "-",
        viewdetail: (
          <button
            onClick={() => handleViewDetailClick(item?.userId?._id)}
            className="bg-blue-500 text-white px-2 py-1 rounded"
          >
            View Detail
          </button>
        ),
        matchlist: (
          <LinkComponent
            lable={"Match List"}
            path={`/match-list/${item.userId?._id}`}
          />
        ),
        status: (
          <div
            className={`${
              item?.status === "completed"
                ? "text-[#66c226]"
                : item?.status === "pending"
                ? "text-[#ec2612]"
                : "text-[#ec6512]  "
            }`}
          >
            {item?.status}
          </div>
        ),
        time: <TimeFormat time={item.createdAt} />,
      });
    });

  const tableHeading = {
    heading: "Transaction",
  };
  console.log({ transactionData }, "data");
  return (
    <div>
      <div className="search-bar" style={{ marginTop: "1rem" }}>
        <input
          type="text"
          placeholder="Search here....."
          value={searchTerm}
          onChange={handleSearch}
          style={{
            padding: "4px",
            width: "100%",
            maxWidth: "300px",
            borderRadius: "4px",
            border: "1px solid",
            marginLeft: "5px",
          }}
        />
      </div>
      <div className="mx-4 pb-4 pt-4">
        <Table
          columns={columns}
          data={transactionData}
          titleData={tableHeading}
          headingSize={"text-[1rem]"}
        />
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
      {loading && <Loading />}
    </div>
  );
};

export default TransactionList;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { handleUserCouponData } from "../networks/adminApi";
import Table from "../components/table";
import Pagination from "../components/pagination";

const CouponList = () => {
  const [coupons, setCoupons] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const { id } = useParams();
  console.log(id);
  const fetchUserCoupon = async () => {
    const res = await handleUserCouponData({ id, currentPage });
    console.log(res, "ndindoinwdnqnwqdiwa");
    setCoupons(res?.data?.coupons);
    setTotalPages(res?.data?.totalPages);
  };
  useEffect(() => {
    fetchUserCoupon();
  }, []);

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Amount", accessor: "amount" },
    { Header: "Redeemed", accessor: "isRedeemed" },
  ];

  const tableHeading = {
    heading: "User Coupon",
  };

  const couponData = [];
  coupons &&
    coupons?.forEach((item) => {
      couponData.push({
        _id: item._id,
        amount: item.amount,
        isRedeemed: item.isRedeemed ? "Redeemed" : "Not Redeemed",
        s_no: item.s_no,
      });
    });

  return (
    <div>
      <div className="mx-4 pb-4">
        <Table
          columns={columns}
          data={couponData}
          titleData={tableHeading}
          headingSize={"text-[1rem]"}
        />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default CouponList;

import React, { useState, useEffect } from "react";
import InputComponent from "../../components/inputComponent";
import SelectComponet from "../../components/selectComponet";
import TextAreaComponent from "../../components/textAreaComponent";
import DateComponent from "../../components/dateComponent";
import InputImage from "../../components/inputImage";
import InputCheck from "../../components/inputCheck";
import Button from "../../components/button";
import { useParams } from "react-router-dom";
import { handleAddGameEvent } from "../../networks/adminApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { handleGetRules } from "../../networks/adminApi";
import SelectTabComponent from "../../components/selecttabComponent";
const AddNewMatch = () => {
  const [selectedGame, setSelectedGame] = useState("");
  const [openGameList, setOpenGameList] = useState(false);
  const [matchTitle, setMatchTitle] = useState("");
  const [matchType, setMatchType] = useState("");
  const [openMatchTypeList, setOpenMatchTypeList] = useState("");
  const [selectedPoolPrize, setSelectedPoolPrize] = useState("");
  const [openPoolPrizeList, setOpenPoolPrizeList] = useState(false);
  const [poolPrizeDescription, setPoolPrizeDescription] = useState("");
  const [matchDate, setMatchDate] = useState("");
  const [matchTime, setMatchTime] = useState("");
  const [version, setVersion] = useState("");
  const [openVersionList, setOpenVersionList] = useState(false);
  const [coverImg, setCoverImg] = useState("");
  const [coverPreviewImg, setCoverPreviewImg] = useState("");
  const [platform, setPlatform] = useState("");
  const [openPlatFormList, setOpenPlatformList] = useState(false);
  const [feeType, setFeeType] = useState("");
  // const [entryType, setEntryType] = useState("");
  // const [openEntryTypeList, setOpenEntryTypeList] = useState("");
  const [entryFee, setEntryFee] = useState("");
  const [pointsPerKill, setPointsPerKill] = useState("");
  const [map, setMap] = useState("");
  const [sponsoredBy, setSponsoredBy] = useState("");
  const [spectralUrl, setSpectralUrl] = useState("");
  const [matchRule, setMatchRule] = useState("");
  const [openMatchRuleList, setOpenMatchRuleList] = useState("");
  const [roomSize, setRoomSize] = useState("");
  const [checkedInput, setCheckedInput] = useState(false);
  const [gameRulesList, setGameRulesList] = useState([]);
  const { name, id } = useParams();

  const navigate = useNavigate();

  const gameList = ["Game 1", "Game 2", "Game 3"];
  const poolPrizeList = [
    "Pool Prize type 1",
    "Pool Prize type 2",
    "Pool Prize type 3",
  ];
  const feeTypeList = ["Free", "Paid"];
  const matchTypeList = ["Solo", "Duo", "Squad"];
  const versionData = ["TPP", "FPP"];
  const platformList = ["Platform 1", "Platform 2", "platForm 3"];
  // const entryTypeList = ["ongoing", "upcoming"];
  const matchRuleList = [
    "MatchRuleList 1",
    "Match Rule List 2",
    "Match Rule List 3",
  ];

  const [coverImgError, setCoverImgError] = useState("");
  const [matchTitleError, setMatchTitleError] = useState("");
  const [selectedPoolPrizeError, setSelectedPoolPrizeError] = useState("");
  const [versionError, setVersionError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [entryfeeError, setEntryFeeError] = useState("");
  const [pointsError, setPointsError] = useState("");
  const [mapError, setMapError] = useState("");
  const [matchRuleError, setmatchRuleError] = useState("");
  const [sponsoredByError, setsponsoredByError] = useState("");
  const [matchDateError, setmatchDateError] = useState("");
  const [matchTimeError, setmatchTimeError] = useState("");
  const [platformError, setPlatformError] = useState("");
  const [spectateError, setSpectateError] = useState("");
  const [matchTypeError, setMatchTypeError] = useState("");
  const [roomSizeError, setRoomSizeError] = useState("");
  const [feeTypeError, setFeeTypeError] = useState("");

  const [MatchRuleOption, setMatchRuleOption] = useState([]);
  const fetchMatchRule = async () => {
    const res = await handleGetRules();

    console.log(res, "iiiiiiiiiiiiiiiiiiiiiiiii");
    if (res?.data?.rules) {
      setMatchRuleOption(res?.data?.rules);
    }
  };

  useEffect(() => {
    fetchMatchRule();
  }, []);

  const validation = () => {
    let valid = true;
    const numberOnlyRegex = /^\d+$/;

    // if (coverImg === "") {
    //   valid = false;
    //   setCoverImgError("Please choose a image");
    // } else {
    //   setCoverImgError("");
    // }

    if (id.trim() === "") {
      valid = false;
      console.log(1);
    }

    if (matchTitle.trim() === "") {
      valid = false;
      setMatchTitleError("Please enter match title");
      console.log(2);
    } else {
      setMatchTitleError("");
    }

    if (selectedPoolPrize.trim() === "") {
      console.log(3);
      valid = false;
      setSelectedPoolPrizeError("Please enter pool prize");
    } else {
      setSelectedPoolPrizeError("");
    }

    if (version.trim() === "") {
      valid = false;
      setVersionError("Please enter Version");
    } else {
      setVersionError("");
    }

    if (poolPrizeDescription.trim() === "") {
      console.log(5);
      valid = false;
      setDescriptionError("Please enter Description");
    } else {
      setDescriptionError("");
    }
    if (feeType === "Paid") {
      console.log(6);
      if (entryFee.trim() === "") {
        console.log(7);
        valid = false;
        setEntryFeeError("Please enter EntryFee");
      } else {
        setEntryFeeError("");
      }
    } else {
      setEntryFeeError("");
    }
    if (pointsPerKill.trim() === "") {
      console.log(8);
      valid = false;
      setPointsError("Please enter PointsKills");
    } else {
      setPointsError("");
    }
    if (map.trim() === "") {
      console.log(9);
      valid = false;
      setMapError("Please enter Map");
    } else {
      setMapError("");
    }
    if (toString(matchRule)?.trim() === "") {
      console.log(10);
      valid = false;
      setmatchRuleError("Please enter Match Rule");
    } else {
      setmatchRuleError("");
    }

    // if (sponsoredBy.trim() === "") {
    //   valid = false;
    //   setsponsoredByError("Please enter SponsoredBy");
    // } else {
    //   setsponsoredByError("");
    // }

    if (matchDate?.trim() === "") {
      valid = false;
      console.log(11);
      setmatchDateError("Please enter MatchDate");
    } else {
      setmatchDateError("");
    }
    if (matchTime.trim() === "") {
      valid = false;
      console.log(12);
      setmatchTimeError("Please enter MatchTime");
    } else {
      setmatchTimeError("");
    }
    // if (platform.trim() === "") {
    //   valid = false;
    //   setPlatformError("Please Select Platform");
    // } else {
    //   setPlatformError("");
    // }
    // if (spectralUrl.trim() === "") {
    //   console.log("dnaiwndioanwidnioawd");
    //   valid = false;
    //   setSpectateError("Please Select spectate Url");
    // } else {
    //   setSpectateError("");
    // }

    if (matchType.trim() === "") {
      console.log(13);
      setMatchTypeError("Please select matchType");
      valid = false;
    } else {
      setMatchTypeError("");
    }

    // if (feeType === "Free" || feeType === undefined) {
    //   setFeeTypeError("Please select fee type");
    //   console.log(14);
    //   valid = false;
    // } else {
    //   setFeeTypeError("");
    // }

    if (roomSize === "") {
      setRoomSizeError("Please enter room size");
      console.log(15);
      valid = false;
    } else {
      setRoomSizeError("");
    }

    console.log(valid);

    return valid;
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await handleGetRules();

      if (res?.success) {
        setGameRulesList(res?.data?.rules);
      }
    };
    fetchData();
  }, []);

  const formDataFunction = () => {
    if (selectedPoolPrize < 1) {
      toast.error("Prize cannote be negative or zero");
      return;
    }
    if (pointsPerKill < 0) {
      toast.error("Kills cannote be negative");
      return;
    }
    if (roomSize < 1) {
      toast.error("Roomsize cannote be negative or zero");
      return;
    }

    if (feeType === "Paid" && entryFee < 1) {
      toast.error("EentryFee cannote be negative or zero");
      return;
    }

    const newData = new FormData();

    // if (coverImg) {
    //   newData.append("image", coverImg);
    // }

    newData.append("game_id", id);
    newData.append("title", matchTitle);
    newData.append("prize", selectedPoolPrize);
    newData.append("date", matchDate.toString());
    newData.append("perKill", pointsPerKill);
    newData.append("entryFee", entryFee);
    newData.append("matchType", matchType);
    newData.append("version", version);
    newData.append("discription", poolPrizeDescription);
    // newData.append("matchCode", roomId);
    newData.append("eventType", "upcoming");
    newData.append("matchTime", matchTime);
    // newData.append("platfrom", platform);
    newData.append("sponsoredBy", sponsoredBy);
    newData.append("matchRule", JSON.stringify(matchRule));
    newData.append("roomSize", roomSize);
    newData.append("map", map);
    newData.append("spectateUrl", spectralUrl);
    newData.append("platform", platform);

    return newData;
  };

  const handleAddEvent = async () => {
    console.log("Clicked :LLLLL");
    console.log({ feeType });
    const getData = formDataFunction();
    console.log("clickedd");
    console.log({ getData });
    if (validation()) {
      console.log("ok");
      const res = await handleAddGameEvent(getData);
      if (res?.success) {
        toast.success("Event added successfully");
        navigate(`/getEvent/${id}`);
      }
    }
  };

  return (
    <div className="mx-4 pt-4 pb-4">
      <div className="text-white">CREATE NEW MATCH</div>
      <div className="text-grey text-[0.5rem]">
        Fill all necessary data to create new match
      </div>
      <div className="text-white mt-4 text-[1.5rem]">{name}</div>
      <div className="">
        <InputComponent
          lable={"Match Title*"}
          width={"w-[50%]"}
          mlm={""}
          value={matchTitle}
          onChange={setMatchTitle}
          type={"text"}
        />
        {matchTitleError && <div className="error">{matchTitleError}</div>}
      </div>
      <InputComponent
        lable={"Pool-Prize*"}
        width={"w-[50%]"}
        value={selectedPoolPrize}
        onChange={setSelectedPoolPrize}
        type="number"
      />
      {selectedPoolPrizeError && (
        <div className="error">{selectedPoolPrizeError}</div>
      )}
      <TextAreaComponent
        lable={"Prize Pool Description*"}
        placeholder={"Enter Prize-Pool description"}
        value={poolPrizeDescription}
        onChange={setPoolPrizeDescription}
      />
      {descriptionError && <div className="error">{descriptionError}</div>}
      <div className="flex flex-row ">
        <div className="w-[25%]">
          <DateComponent
            lable="Match Date*"
            width={"w-[100%]"}
            value={matchDate}
            onChange={setMatchDate}
            type={"date"}
          />
          {matchDateError && <div className="error">{matchDateError}</div>}
        </div>
        <div className="flex flex-col w-[25%] ml-2">
          <DateComponent
            lable="Match Time*"
            width={"w-[100%]"}
            value={matchTime}
            onChange={setMatchTime}
            type={"time"}
          />
          {matchTimeError && <div className="error">{matchTimeError}</div>}
        </div>
        <div className="flex flex-col w-[25%] ml-2">
          <SelectComponet
            lable="Match Type*"
            width={"w-[100%]"}
            value={matchType}
            onSelect={setMatchType}
            data={matchTypeList}
          />
          {matchTypeError && <div className="error">{matchTypeError}</div>}
        </div>
        <div className="flex flex-col w-[25%] ml-2">
          <SelectComponet
            lable="Version*"
            width={"w-[100%]"}
            value={version}
            onSelect={setVersion}
            data={versionData}
          />
          {/* {versionError && <div className="error">{versionError}</div>} */}
        </div>
      </div>
      <div>
        {/* <div className="flex flex-col">
          <InputImage
            lable={"Cover Image*"}
            width={"w-[50%]"}
            setFile={setCoverImg}
            setFilePreview={setCoverPreviewImg}
          />
          {coverImgError && <div className="error">{coverImgError}</div>}
        </div> */}
        {/* <SelectComponet
          lable={"Platform*"}
          width={"w-[50%]"}
          height={"h-[30px]"}
          value={platform}
          openList={openPlatFormList}
          data={platformList}
          onSelect={setPlatform}
        />
        {platformError && <div className="error">{platformError}</div>} */}
      </div>

      <SelectTabComponent
        lable={"Match Rules*"}
        width={"w-[43e%]"}
        height={"h-[30px]"}
        value={matchRule}
        openList={openMatchRuleList}
        data={MatchRuleOption}
        onSelect={setMatchRule}
      />
      {matchRuleError && <div className="error">{matchRuleError}</div>}
      <div className="flex flex-row">
        <div className="w-[25%]">
          <InputComponent
            lable={"Map*"}
            width={"w-[100%]"}
            value={map}
            onChange={setMap}
          />
          {mapError && <div className="error">{mapError}</div>}
        </div>
        <div className="w-[25%] ml-2">
          <SelectComponet
            lable={"Select Fee Type*"}
            width={"w-[100%]"}
            height={"h-[25px]"}
            value={feeType}
            onSelect={setFeeType}
            data={feeTypeList}
          />
          {feeTypeError && <div className="error">{feeTypeError}</div>}
        </div>
        {feeType === "Paid" && (
          <div className="w-[25%] ml-2">
            <InputComponent
              lable={"Entry Fee*"}
              width={"w-[100%]"}
              value={entryFee}
              onChange={setEntryFee}
              type={"number"}
            />
            {entryfeeError && <div className="error">{entryfeeError}</div>}
          </div>
        )}
        <div className="w-[25%] ml-2">
          <InputComponent
            lable={"Points/Kills*"}
            width={"w-[100%]"}
            value={pointsPerKill}
            onChange={setPointsPerKill}
            type={"number"}
          />
          {pointsError && <div className="error">{pointsError}</div>}
        </div>
      </div>
      <div className="flex flex-col">
        <InputComponent
          lable={"Sponsored by"}
          width={"w-[100%]"}
          value={sponsoredBy}
          onChange={setSponsoredBy}
        />
        {sponsoredByError && <div className="error">{sponsoredByError}</div>}
      </div>
      <InputComponent
        lable={"Spectate URL"}
        width={"w-[100%]"}
        value={spectralUrl}
        onChange={setSpectralUrl}
      />
      {spectateError && <div className="error">{spectateError}</div>}
      {/* <SelectComponet
        lable={"Match Rules*"}
        width={"w-[50%]"}
        height={"h-[30px]"}
        value={matchRule}
        openList={openMatchRuleList}
        data={matchRuleList}
        onSelect={setMatchRule}
   

       <InputCheck
        lable={"Private Match"}
        width={"w-[50%]"}
        value={checkedInput}
        onChange={setCheckedInput}
      /> */}
      <div className="flex flex-col">
        <InputComponent
          lable={"Room Size*"}
          width={"w-[100%]"}
          value={roomSize}
          onChange={setRoomSize}
          type={"number"}
        />
        {roomSizeError && <div className="error">{roomSizeError}</div>}
      </div>
      <div className="mt-4 flex justify-end cursor-pointer">
        <button
          style={{
            width: "10vw",
            height: "5vh",
            cursor: "pointer",
            background: "#2657cf",
            color: "white",
          }}
          onClick={() => handleAddEvent()}
        >
          Add Event
        </button>
      </div>
    </div>
  );
};

export default AddNewMatch;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  usersProfile: {},
  authenticated: false,
  allUserList: [],
  allGameList: [],
  allLudoList: [],
  prizePoolData: {},
  storeInputState: {},
};
const usersProfileSlice = createSlice({
  name: "userProfile/Slice",
  initialState,
  reducers: {
    setUsersProfile: (state, action) => {
      state.usersProfile = action.payload;
    },
    setAdminAuthenticated: (state, action) => {
      state.authenticated = action.payload;
    },
    setAllUserList: (state, action) => {
      state.allUserList = action.payload;
    },
    setAllGameList: (state, action) => {
      state.allGameList = action.payload;
    },
    setAllLudoList: (state, action) => {
      state.allLudoList = action.payload;
    },
    setPrizePoolData: (state, action) => {
      state.prizePoolData = { ...state.prizePoolData, ...action.payload };
    },
    setStoreInputState: (state, action) => {
      state.storeInputState = { ...state.storeInputState, ...action.payload };
    },
  },
});

export default usersProfileSlice.reducer;
export const {
  setUsersProfile,
  setAdminAuthenticated,
  setAllUserList,
  setAllGameList,
  setAllLudoList,
  setPrizePoolData,
  setStoreInputState,
} = usersProfileSlice.actions;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputComponent from "../../components/inputComponent";
import Button from "../../components/button";
import { handleAddRoomId, handleGameEventById } from "../../networks/adminApi";
import { toast } from "react-toastify";
import { Modal } from "@mui/material"; // Import MUI Modal for the popup
import { ENDPOINTS_API } from "../../networks/apiConstants";
import { BASE_URL } from "../../commonservice/commonservice";
import { callAPI } from "../../networks/callApi";

const AddRoomId = () => {
  const { id } = useParams();
  const [roomId, setRoomId] = useState("");
  const [roomPassword, setRoomPassword] = useState("");
  const [roomIdError, setRoomIdError] = useState("");
  const [roomPasswordError, setRoomPasswordError] = useState("");
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [showRoomDetails, setShowRoomDetails] = useState(false); // State to control room details display
  const [modalType, setModalType] = useState(null); // State to track which button opened the modal
  const navigate = useNavigate();

  const fetchEvent = async () => {
    const res = await handleGameEventById({ id });
    if (res?.data) {
      setRoomId(res?.data?.roomId);
      setRoomPassword(res?.data?.roomPassword);
    }
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  const validate = () => {
    let valid = true;
    if (roomId.trim() === "") {
      setRoomIdError("Please enter room ID");
      valid = false;
    } else {
      setRoomIdError("");
    }
    if (roomPassword.trim() === "") {
      setRoomPasswordError("Please enter room password");
      valid = false;
    } else {
      setRoomPasswordError("");
    }
    return valid;
  };

  const handleCreateRoom = async () => {
    if (validate()) {
      const res = await handleAddRoomId({
        roomId: roomId,
        roomPassword: roomPassword,
        gameEventId: id,
      });

      if (res?.success) {
        toast.success("Room id created successfully");
        navigate(-1);
      }
    }
  };

  const handleRoomIdChange = (value) => {
    setRoomId(value);
    if (roomIdError) setRoomIdError("");
  };

  const handleRoomPasswordChange = (value) => {
    setRoomPassword(value);
    if (roomPasswordError) setRoomPasswordError("");
  };

  // Function to open the modal
  const handleShowModal = (type) => {
    setShowModal(true);
    setModalType(type);
  };

  const handleModal = (data) => {
    setShowModal(true);
    setModalType(data);
  };

  // Function to handle modal confirmation
  const handleModalConfirm = (confirm) => {
    if (confirm) {
      if (modalType === "showRoomId") {
        setShowRoomDetails(true);
        // Show room details if user clicks "Yes"
      } else if (modalType === "updateRoom") {
        handleCreateRoom(); // Call update room API if user clicks "Yes"
      }
    }
    setShowModal(false); // Close the modal
  };

  const handleaddvideolink = () => {
    callAPI(
      BASE_URL + ENDPOINTS_API.ROOMID_NOTIFICATION,
      "PUT",
      {
        "Content-type": "application/json",
      },
      JSON.stringify({ gameEventId: id })
    )
      .then((res) => {
        console.log(res.data);
        toast.success("Notification Sent Successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to send notification");
      });
  };

  return (
    <div className="mx-4 mt-2">
      <div className="text-white">Create Room Id and Password</div>

      <InputComponent
        lable={"Room Id"}
        placeholder={"Please enter room Id"}
        value={roomId}
        onChange={handleRoomIdChange}
      />
      {roomIdError && (
        <div className="text-white text-sm mt-1">{roomIdError}</div>
      )}

      <InputComponent
        lable={"Room Password"}
        placeholder={"Please enter room password"}
        value={roomPassword}
        onChange={handleRoomPasswordChange}
      />
      {roomPasswordError && (
        <div className="text-white text-sm mt-1">{roomPasswordError}</div>
      )}
      <div className="flex justify-center items-center ">
        <Button
          lable={"Update Room"}
          mt={"mt-4"}
          onClick={() => handleModal("updateRoom")} // Open modal for update room
        />
      </div>

      <div className="mt-[40px]">
        <Button
          lable={"Show room Id"}
          onClick={() => handleShowModal("showRoomId")}
        />
        <Button onClick={handleaddvideolink} lable={"Send Notification"} />
      </div>

      {/* Modal Component */}
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div className="modal-content bg-white p-4 rounded-lg shadow-lg mx-auto mt-20 w-[300px] text-center">
          <h2 className="text-lg font-bold mb-4">
            {modalType === "showRoomId" ? "Show Room ID" : "Update Room"}
          </h2>
          <p>
            {modalType === "showRoomId"
              ? "Do you want to show your room ID and password?"
              : "Are you sure you want to update the room details?"}
          </p>
          <div className="flex justify-around mt-4">
            <Button lable={"Yes"} onClick={() => handleModalConfirm(true)} />
            <Button lable={"No"} onClick={() => handleModalConfirm(false)} />
          </div>
        </div>
      </Modal>

      {/* Display room ID and password conditionally */}
      {showRoomDetails && (
        <div className="mt-4 text-white">
          <h3>Room ID: {roomId}</h3>
          <h4>Room Password: {roomPassword}</h4>
        </div>
      )}
    </div>
  );
};

export default AddRoomId;

import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";

const SelectTabComponent = ({
  lable,
  value,
  onSelect,
  width,
  mlm,
  height = "h-6",
  data,
}) => {
  const [openList, setOpenList] = useState(false);
  return (
    <div className={`${width} ${mlm} relative`}>
      <div className="mt-2 text-white">{lable}</div>
      <button
        onClick={() => setOpenList(!openList)}
        className="w-full flex items-start "
      >
        <div
          className={`relative border border-grey w-full mt-2 rounded pl-2 text-sm text-white flex items-center ${height}`}
        >
          {value === "" ? "--Select--" : value?.name}

          {openList ? (
            <FaChevronUp className="h-2 w-2 text-white absolute right-1 " />
          ) : (
            <FaChevronDown className="h-2 w-2 text-white absolute right-1 " />
          )}
        </div>
      </button>
      {openList && (
        <div className="flex-col absolute z-20 h-[300px]  max-h-[300px] overflow-auto">
          {data?.map((item) => {
            return (
              <div key={item}>
                <button
                  className="bg-darkBlue  text-white py-2 w-[40vw]"
                  onClick={() => [onSelect(item), setOpenList(false)]}
                >
                  <div>Game: {item?.name}</div>
                  {/* {item.rules.map((item, index) => {
                    return (
                      <div key={item}>
                        {index + 1} {item}
                      </div>
                    );
                  })} */}
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SelectTabComponent;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAdminProfile } from "../networks/adminApi";
import Cookies from "js-cookie";
import { setAdminAuthenticated } from "../store/reducer/usersReducer";
import { useDispatch } from "react-redux";
import { IoMdLogOut } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip"; // Import Tooltip from MUI

const Header = () => {
  const [adminDetails, setAdminDetails] = useState("");
  const [selectedTab, setSelectedTab] = useState("");
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setSelectedTab(location?.pathname);
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAdminProfile();

      if (res) {
        setAdminDetails(res);
      }
    };
    if (localStorage.getItem("token")) {
      fetchData();
    }
  }, []);

  const headerData = [
    {
      _id: 1,
      img: require("../assets/image/dashboard.png"),
      alt: "dashboard img",
      text: "Dashboard",
      path: "/",
    },
    {
      _id: 2,
      img: require("../assets/image/game.png"),
      alt: "game img",
      path: "/game",
      text: "Match",
    },
    {
      _id: 3,
      img: require("../assets/image/time.png"),
      alt: "Ludo img",
      path: "/addLudoList",
      text: "Ludo",
    },
    {
      _id: 4,
      img: require("../assets/image/person.png"),
      alt: "person img",
      text: "User List",
      path: "/userList",
    },
    {
      _id: 5,
      img: require("../assets/image/cup.png"),
      alt: "file img",
      text: "Top Player",
      path: "/topPlayer",
    },
    {
      _id: 6,
      img: require("../assets/image/gift.png"),
      alt: "gift img",
      text: "Scratch Card",
      path: "/coupon",
    },
    {
      _id: 7,
      img: require("../assets/image/notification.png"),
      alt: "speaker img",
      text: "Announcement",
      path: "/announcementList",
    },
    {
      _id: 8,
      img: require("../assets/image/file.png"),
      alt: "graph img",
      text: "Match Rules",
      path: "/matchRules",
    },
    {
      _id: 9,
      img: require("../assets/image/notes.png"),
      alt: "transaction img",
      text: "Transaction",
      path: "/transaction",
    },
    {
      _id: 10,
      img: require("../assets/image/three.png"),
      alt: "referral img",
      text: "Referral",
      path: "/referral",
    },
    {
      _id: 11,
      img: require("../assets/image/support.png"),
      alt: "support img",
      text: "Support",
      path: "/support",
    },
    {
      _id: 11,
      img: require("../assets/image/video.png"),
      alt: "support img",
      text: "Videolink",
      path: "/videolink",
    },
    {
      _id: 12,
      img: require("../assets/image/atm (1).png"),
      alt: "support img",
      text: "Withdrawl",
      path: "/withdrawl",
    },
    {
      _id: 13,
      img: require("../assets/image/call-phone (1).png"),
      alt: "support img",
      text: "Contact Us",
      path: "/contactUs",
    },
    {
      _id: 14,
      img: require("../assets/image/faq (1).png"),
      alt: "support img",
      text: "Add FaQ",
      path: "/addquestion",
    },
    {
      _id: 15,
      img: require("../assets/image/upload (1).png"),
      alt: "support img",
      text: "Apk Upload",
      path: "/apkupload",
    },
    // {
    //   _id: 16,
    //   img: require("../assets/image/support.png"),
    //   alt: "support img",
    //   text: "Customer Support",
    //   path: "/customerSupport",
    // },
    // {
    //   _id: 17,
    //   img: require("../assets/image/notes.png"),
    //   alt: "upi image",
    //   text: "Upi Id",
    //   path: "/upi-update",
    // },
  ];

  const handleLogout = () => {
    setOpenLogoutDialog(true);
  };

  const confirmLogout = () => {
    Cookies.remove("Token");
    localStorage.removeItem("token");
    navigate("/login");
    dispatch(setAdminAuthenticated(false));
    toast.success("Logout Successfully");
    setOpenLogoutDialog(false);
  };

  const cancelLogout = () => {
    setOpenLogoutDialog(false);
  };

  return (
    <div className="bg-blue h-16 flex flex-row items-center w-[100%] border-b-4 border-[#273da7] justify-between pr-8 absolute top-0 z-30">
      <div className="flex items-center w-[100%]">
        <div className="flex flex-row items-center ml-2 w-[200px] max-[500px]:w-[50px]">
          <Link to="/editProfile" className="flex flex-row items-center">
            <img
              src={
                adminDetails?.image
                  ? adminDetails?.image
                  : require("../assets/image/profile.png")
              }
              alt="Profile Img"
              className="h-[30px] w-[30px]"
            />
            <div className="ml-2 max-[500px]:hidden">
              <div className="text-white text-[.5rem]">
                {adminDetails?.name}
              </div>
              <div className="text-white text-[.5rem] ">
                {adminDetails?.email}
              </div>
            </div>
          </Link>
        </div>
        <div className="ml-[10px] hide-scrollbar flex flex-row overflow-scroll w-[60%] min-w-[200px]">
          {headerData.map((item, index) => {
            return (
              <Tooltip key={index} title={item.text} placement="top">
                {" "}
                {/* Add Tooltip here */}
                <Link to={item.path}>
                  <button
                    className={`ml-4 p-2 h-16 rounded-[6px] ${
                      selectedTab === item.path
                        ? "bg-gradient-to-r from-primary to-secondary  w-20 "
                        : "w-8"
                    }`}
                  >
                    <div className="flex flex-col items-center justify-center ">
                      <img
                        src={item.img}
                        alt={item.alt}
                        className="h-[12px] w-[12px]"
                      />
                      {selectedTab === item.path && (
                        <div className="text-white text-[.5rem] mt-2 font-bold">
                          {item.text}
                        </div>
                      )}
                    </div>
                  </button>
                </Link>
              </Tooltip>
            );
          })}
        </div>
      </div>
      <div className="flex flex-row item-center">
        <button onClick={handleLogout} className="text-white mr-2">
          <IoMdLogOut />
        </button>
      </div>

      {/* Logout Confirmation Dialog */}
      <Dialog
        open={openLogoutDialog}
        onClose={cancelLogout}
        aria-labelledby="logout-confirmation-dialog-title"
        aria-describedby="logout-confirmation-dialog-description"
      >
        <DialogTitle
          style={{ fontSize: "20px" }}
          id="logout-confirmation-dialog-title"
        >
          CONFIRM LOGOUT
        </DialogTitle>
        <DialogContent>Are you sure you want to logout?</DialogContent>
        <DialogActions>
          <Button onClick={cancelLogout} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmLogout} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Header;

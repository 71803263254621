import React, { useEffect, useState } from "react";
import { handleGetRefferalList } from "../networks/adminApi";
import Table from "../components/table";
import Pagination from "../components/pagination";
const ReferralList = () => {
  const [allReferralList, setAllReferralList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const fetchReferrlList = async () => {
    const res = await handleGetRefferalList();
    console.log(res, "Refferaal");
    if (res?.data) {
      setAllReferralList(res?.data);
    }
  };

  useEffect(() => {
    fetchReferrlList();
  }, []);

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    { Header: "Mobile", accessor: "mobile" },
  ];
  const referralData = [];
  allReferralList &&
    allReferralList.forEach((item, index) => {
      referralData.push({
        _id: item._id,
        s_no: index + 1,
        status: item.status,
        type: item.type,
        mobile: item.referredBy.mobile,
      });
    });
  const tableHeading = {
    heading: "Referral List",
  };
  console.log({ allReferralList, referralData });

  return (
    <div>
      <div className="mx-4 pb-4 pt-4">
        <Table
          columns={columns}
          data={referralData}
          titleData={tableHeading}
          headingSize={"text-[1rem]"}
        />
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default ReferralList;

import React, { useState, useEffect } from "react";
import "./style.css"; // Make sure to add appropriate styles in this CSS file
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/pagination";
import Table from "../../components/table";
import { ENDPOINTS_API } from "../../networks/apiConstants";
import { BASE_URL } from "../../commonservice/commonservice";
import { callAPI } from "../../networks/callApi";
import moment from "moment";

const Index = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("open"); // Initially set to "open"
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [supportList, setSupportList] = useState([]);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handlereply = (id) => {
    navigate(`/ticketreply/${id}`);
  };

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "User Name", accessor: "username" },
    { Header: "Ticket", accessor: "ticket" },
    { Header: "Issue", accessor: "issue" },
    { Header: "Last Update", accessor: "lastupdate" },
    { Header: "Status", accessor: "status" },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  const feedbackList = supportList
    .filter((item) => item.status === activeButton) // Filter by the selected status
    .map((item, index) => ({
      s_no: index + 1,
      ticket: item?.description,
      issue: item?.issueId?.title,
      username: item?.userId  ?  item?.userId?.name : "",
      lastupdate: moment(item?.updatedAt).format("DD-MM-YYYY , LT"),
      status: item?.status,
      action: (
        <button
          onClick={() => handlereply(item?._id)}
          style={{
            border: "1px solid",
            width: "8vw",
            height: "5vh",
            borderRadius: "4px",
          }}
        >
          Reply
        </button>
      ),
    }));

  const tableHeading = {
    heading: "Support List",
  };

  const handleGetAllTickets = () => {
    callAPI(`${BASE_URL + ENDPOINTS_API.GETALL_ISSUE}?page=${currentPage}`, "GET", {
      "Content-type": "application/json",
    })
      .then((res) => {
        if(res?.data){
        setSupportList(res?.data?.tickets);
        setTotalPages(res?.data?.totalPages)}
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetAllTickets();
  }, [currentPage]);

  return (
    <div>
      <div className="commission-slab-container">
        <div style={{ marginTop: "1rem", display: "flex", gap: "10px" }}>
          <button
            type="btn"e
            className={`user_btn ${activeButton === "open" ? "activ" : ""}`}
            onClick={() => handleButtonClick("open")}
          >
            Open
          </button>
          <button
            type="btn"
            className={`close_btn ${activeButton === "closed" ? "activ" : ""}`}
            onClick={() => handleButtonClick("closed")}
          >
            Closed
          </button>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            onClick={() => navigate("/AddTicketIssue")}
            type="btn"
            className="raiseticket_btn"
          >
            Ticket Issue
          </button>
        </div>
          <Table
            columns={columns}
            data={feedbackList}
            titleData={tableHeading}
            headingSize={"text-[1rem]"}
          />
      
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default Index;

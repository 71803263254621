import React, { useEffect, useState } from "react";
import LinkComponent from "../components/linkComponent";
import {
  handleGetUserNotification,
  handleGetAdminNotification,
} from "../networks/adminApi";
import Table from "../components/table";
import Pagination from "../components/pagination";
import { useNavigate } from "react-router-dom";
import Loading from "./loader/Loading";

const AnnouncementList = () => {
  const [userNotificationList, setUserNotificationList] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const fetchuserNotification = async () => {
    setLoading(true);
    try {
      const res = await handleGetUserNotification(currentPage);
      if (res?.data) {
        setUserNotificationList(res?.data);
        setTotalPages(res?.data?.totalPages);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchuserNotification();
  }, [currentPage]);

  const columns = [
    { Header: "S.No", accessor: "Sno" },
    { Header: "Title", accessor: "title" },
    { Header: "Body", accessor: "body" },
    { Header: "User List", accessor: "userList" },
  ];

  const tableHeading = {
    heading: "Announcement List",
  };

  const announcementData = [];

  userNotificationList &&
    userNotificationList?.forEach((item) => {
      announcementData.push({
        _id: item._id,
        Sno: item.s_no,
        title: item.title,
        body: item.body,
        userList: (
          <button
            onClick={() => navigate(`/announcementshowuser/${item._id}`)}
            style={{
              background: "#2657cf",
              height: "4.5vh",
              width: "13vw",
              borderRadius: "5px",
            }}
          >
            View Notification List
          </button>
        ),
      });
    });

  return (
    <div>
      <div className="bg-darkBlue">
        <div style={{ display: "flex", gap: "10px", marginTop: "1rem" }}>
          <LinkComponent path={"/announcement"} lable="Send Notification" />
          <LinkComponent
            path={"/announcementtoall"}
            lable="Send Notification To All"
          />
        </div>

        <Table
          columns={columns}
          data={announcementData}
          titleData={tableHeading}
          headingSize={"text-[1rem]"}
        />
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
      {loading && <Loading />}
    </div>
  );
};

export default AnnouncementList;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { handleUserTransaction } from "../networks/adminApi";
import Table from "../components/table";
import moment from "moment";
import Button from "../components/button";
import Pagination from "../components/pagination";

const UserTransaction = () => {
  const [transactionList, setTransactionList] = useState([]);
  const [wallet, setWallet] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const { id } = useParams();

  const fetchUserTransaction = async () => {
    const res = await handleUserTransaction({ id, wallet, currentPage });
    setTransactionList(res?.data?.transactions);
    console.log(res, "USER TRANSACTION LIST");
    setTotalPages(res?.data?.totalPages);
  };

  useEffect(() => {
    fetchUserTransaction();
  }, [wallet, currentPage]);

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Amount", accessor: "amount" },
    { Header: "Status", accessor: "status" },
    { Header: "Type", accessor: "type" },
    { Header: "Game Id", accessor: "gameId" },
    { Header: "Time", accessor: "time" },
  ];

  const columns1 = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Amount", accessor: "amount" },
    { Header: "Status", accessor: "status" },
    { Header: "Type", accessor: "type" },
    { Header: "Time", accessor: "time" },
  ];

  const tableHeading = {
    heading: "Transaction Heading",
  };

  const transactionData = [];
  transactionList &&
    transactionList?.forEach((item) => {
      transactionData.push({
        _id: item._id,
        amount: item.amount,
        status: item.status,
        type: item.type,
        gameId: item?.challenge?.appRoomId || item?.gameEvent?.uid || "-",
        s_no: item.s_no,
        time: moment(item.createdAt).format("DD-MM-YYYY , LT"),
      });
    });

  return (
    <div className="mx-4 pb-4">
      <div className="flex flex-row">
        <Button lable={"Wallet"} onClick={() => setWallet(false)} />
        <Button
          lable={"Transaction"}
          onClick={() => setWallet(true)}
          ml={"ml-[20px]"}
        />
      </div>
      <Table
        columns={wallet ? columns1 : columns}
        data={transactionData}
        titleData={tableHeading}
        headingSize={"text-[1rem]"}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default UserTransaction;

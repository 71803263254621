import React from "react";
import { Modal, Button } from "@mui/material";

const NetworkModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="block-unblock-modal"
      aria-describedby="block-unblock-description"
    >
      <div
        onClick={handleClose}
        className="items-center justify-center flex h-[100%]"
      >
        <div className="bg-white px-[10px] w-[250px] text-center py-4 rounded-[20px]">
          <div className="text-black font-bold text-[24px]">Network Issue</div>
          <div>
            Looks like our site is taking a short break! We’re fixing things, so
            please refresh the page or check back in a few.
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NetworkModal;

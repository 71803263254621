import React, { useEffect, useState } from "react";
import Table from "../components/table";
import {
  handleGetGametopPlayers,
  handleGetLudoTopPlayer,
} from "../networks/adminApi";
import Button from "../components/button";
import Pagination from "../components/pagination";

const TopPlayer = () => {
  const [playerData, setPlayerData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("GAME");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  // Fetch Game Top Players
  const fetchGameTopPlayers = async (page) => {
    try {
      const res = await handleGetGametopPlayers(page);

      console.log(res, "TOPPLAYERGAME");

      if (res?.data) {
        setPlayerData(
          res?.data.map((item) => ({
            id: item._id,
            s_no: item.s_no,
            winningAmount: item?.totalEarnings,
            email: item?.email,
            name: item?.username,
            userId: item?.joinedByUserId,
            // totalEarnings: item.totalEarnings,
            // userId: item.userId,
            // username: item.participantData.map((p) => p.username).join(", "),
            // position: item.participantData.map((p) => p.position).join(", "),
            // winningamount: item.participantData
            //   .map((p) => p.winningAmount)
            //   .join(", "),
            // kills: item.participantData.map((p) => p.kills).join(", "),
          }))
        );
        setTotalPages(res.data.totalPages);
      }
    } catch (error) {
      console.error("Error fetching game top players", error);
    }
  };

  // Fetch Ludo Top Players
  const fetchLudoTopPlayers = async (page) => {
    try {
      const res = await handleGetLudoTopPlayer(page);
      console.log(res?.data, "TOPPLAYERLUDO");
      if (res?.data) {
        setPlayerData(
          res?.data.map((item) => ({
            id: item._id,
            s_no: item.s_no,
            totalEarnings: item.totalEarnings,
            username: item?.user?.name || "null",
            gender: item?.user?.gender || "null",
            winningamount: item?.user?.winningAmount || "null",
          }))
        );
        setTotalPages(res.data.totalPages);
      }
    } catch (error) {
      console.error("Error fetching ludo top players", error);
    }
  };

  // Fetch data when the selected tab or current page changes
  useEffect(() => {
    if (selectedTab === "GAME") {
      fetchGameTopPlayers(currentPage);
    } else if (selectedTab === "LUDO") {
      fetchLudoTopPlayers(currentPage);
    }
  }, [selectedTab, currentPage]);

  // Define columns based on selected tab
  const gameColumns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Total Earnings", accessor: "winningAmount" },
    { Header: "User Id", accessor: "userId" },
    { Header: "Name", accessor: "name" },
    { Header: "Email", accessor: "email" },

    // { Header: "User Name", accessor: "username" },
    // { Header: "Position", accessor: "position" },
    // { Header: "Winning Amount", accessor: "winningamount" },
    // { Header: "Kills", accessor: "kills" },
  ];

  const ludoColumns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Total Earnings", accessor: "totalEarnings" },
    { Header: "User Name", accessor: "username" },
    { Header: "Winning Amount", accessor: "winningamount" },
  ];

  const tableHeading =
    selectedTab === "GAME" ? "Top Game Player" : "Top Ludo Player";

  return (
    <div className="mx-4">
      <div className="flex flex-row mt-8">
        <Button
          lable="E-sport"
          width="w-[80px]"
          onClick={() => {
            setSelectedTab("GAME");
            setCurrentPage(1); // Reset to page 1 when switching tabs
          }}
        />
        <Button
          lable="Ludo"
          width="w-[80px]"
          ml="ml-4"
          onClick={() => {
            setSelectedTab("LUDO");
            setCurrentPage(1); // Reset to page 1 when switching tabs
          }}
        />
      </div>

      <Table
        columns={selectedTab === "GAME" ? gameColumns : ludoColumns}
        data={playerData}
        titleData={{ heading: tableHeading }}
        headingSize="text-[1rem]"
      />

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default TopPlayer;

import React from "react";

const Button = ({
  lable,
  width,
  ml,
  mt,
  rounded = "rounded-[4px]",
  onClick,
  disabled = false,
}) => {
  return (
    <div className={` ${mt} `}>
      <button
        disabled={disabled}
        onClick={onClick}
        className={`${ml} ${width}   ${rounded}  bg-gradient-to-r from-primary to-secondary  text-white text-[.7rem] px-4 py-[2px]`}
      >
        {lable}
      </button>
    </div>
  );
};

export default Button;

import React, { useEffect, useState } from "react";
import InputComponent from "../../components/inputComponent";
import { Link } from "react-router-dom";
import { handleAdminLogin } from "../../networks/adminApi";
import Button from "../../components/button";
import { setAdminAuthenticated } from "../../store/reducer/usersReducer";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PasswordInputComponent from "../../components/passwordInputComponent";
import { generateToken } from "../../firebase";
import { callAPI } from "../../networks/callApi";
import { BASE_URL } from "../../commonservice/commonservice";
import { ENDPOINTS_API } from "../../networks/apiConstants";

const Login = () => {
  const [email, setEmil] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [token, setToken] = useState();

  const generateDeviceToken = async () => {
    const resp = await generateToken();
    console.log("resp", resp);
    const tken = localStorage.getItem("deviceToken");
    setToken(tken);
  };
  console.log(token, "devicetoken");

  useEffect(() => {
    generateDeviceToken();
  }, []);

  const validation = () => {
    let valid = true;
    if (email.trim() === "") {
      valid = false;
    }

    if (password.trim() === "") {
      valid = false;
    }
    return valid;
  };

  const handleLogin = async () => {
    if (validation()) {
      const data = {
        email,
        password,
        deviceToken: localStorage.getItem("deviceToken"),
      };

      try {
        const res = await callAPI(
          BASE_URL + ENDPOINTS_API.LOGIN,
          "POST",
          {
            "content-type": "application/json",
          },
          JSON.stringify(data)
        );

        if (res?.success) {
          dispatch(setAdminAuthenticated(true));
          Cookies.set("Token", res?.data?.token);
          localStorage.setItem("token", res?.data?.token);
          navigate("/");
          toast.success("Login successfully");
          window.location.reload();
        } else if (res?.status === 403) {
          // Automatic logout on 403 error
          dispatch(setAdminAuthenticated(false));
          Cookies.remove("Token");
          localStorage.removeItem("token");
          toast.error("Session expired, please log in again.");
          navigate("/login"); // Redirect to login page
        } else {
          toast.error(res?.message);
        }
      } catch (error) {
        toast.error("Something went wrong!");
      }
    } else {
      toast.warn("Please fill all the details");
    }
  };

  return (
    <div className="flex items-center bg-blue justify-center absolute w-[100%] h-[100vh] top-0 left-0 z-50">
      <div className="bg-darkBlue p-4 rounded-[10px]">
        <InputComponent
          lable={"Email"}
          placeholder={"Enter your email"}
          value={email}
          onChange={setEmil}
          width={"w-[400px]"}
        />
        <PasswordInputComponent
          lable={"Password"}
          placeholder={"Enter your password"}
          value={password}
          onChange={setPassword}
          width={"w-[400px]"}
        />
        <div className="flex place-content-end  pr-2 mt-2 text-white">
          <Link to="/forgotPassword">Forgot Password?</Link>
        </div>
        <div className="flex items-center justify-center mt-6">
          <Button lable={"Login"} onClick={handleLogin} />
        </div>
      </div>
    </div>
  );
};

export default Login;

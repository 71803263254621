import React, { useEffect, useState } from "react";
import Table from "../components/table";
import { ENDPOINTS_API } from "../networks/apiConstants";
import { BASE_URL } from "../commonservice/commonservice";
import { callAPI } from "../networks/callApi";
import { FaEye } from "react-icons/fa";
import { Modal } from "@mui/material"; // MUI modal for displaying the image

const ErrorChallanges = () => {
  const [errorChallanges, setErrorChallanges] = useState("");
  const [open, setOpen] = useState(false); // State for modal visibility
  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image

  const handleGetErrorchallanges = () => {
    callAPI(BASE_URL + ENDPOINTS_API.ERROR_CHALLANGES, "GET", {
      "Content-type": "application/json",
    })
      .then((res) => {
        setErrorChallanges(res?.data?.challenges);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetErrorchallanges();
  }, []);

  const handleOpenModal = (image) => {
    setSelectedImage(image); // Set the selected screenshot URL
    setOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setOpen(false); // Close the modal
    setSelectedImage(null); // Clear the selected image
  };

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "User", accessor: "user" },
    { Header: "Email", accessor: "email" },
    { Header: "Mobile", accessor: "mobile" },
    {
      Header: "Screenshot",
      accessor: "screenshot",
    },
  ];

  const errorchallangesdata = [];

  console.log(errorChallanges, "asdfghjk");
  errorChallanges &&
    errorChallanges.forEach((item, index) => {
      errorchallangesdata.push({
        s_no: index + 1,
        user: item?.createdBy?.name,
        email: item?.createdBy?.email,
        mobile: item?.createdBy?.mobile,
        screenshot: item?.screenshot ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer", // Change cursor to pointer to indicate click
            }}
            onClick={() => handleOpenModal(item?.screenshot)} // Open the modal with the screenshot
          >
            <FaEye />
          </div>
        ) : (
          "No img uploaded"
        ),
      });
    });

  const tableHeading = {
    heading: "Error challanges List",
  };

  return (
    <>
      <div>
        <Table
          columns={columns}
          data={errorchallangesdata}
          titleData={tableHeading}
          headingSize={"text[1rem]"}
        />
      </div>

      {/* Modal to display the screenshot */}
      <Modal open={open} onClose={handleCloseModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",

            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "5px",
            outline: "none",
          }}
        >
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Screenshot"
              style={{ minWidth: "25vw", maxHeight: "70vh" }}
            />
          ) : (
            <p>No screenshot available</p>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ErrorChallanges;

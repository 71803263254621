/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import "./style.css";
import { toast } from "react-toastify";
import { IoIosEye } from "react-icons/io";
import { FaDownload, FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { json, useNavigate } from "react-router-dom";
import { ENDPOINTS_API } from "../../networks/apiConstants";
import { BASE_URL } from "../../commonservice/commonservice";
import { callAPI } from "../../networks/callApi";
import axios from "axios";

import { Box, Button, Modal, Typography } from "@mui/material";


const index = () => {
  const [apk, setApk] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apkFile, setApkFile] = useState("");
  const [apkVersion, setApkVersion] = useState("");
  const [mandatory, setMandatory] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [disable,setDisable]=useState(false)
  const [data, setData] = useState({
    is_mandatory: false,
    apkVersion: "",
    image: "",
    url: "",
    name: "",
  });
  console.log(apkFile, mandatory, apkVersion, "klkjhiih");
  const [showFileInput, setShowFileInput] = useState(false);
  const [name, setName] = useState();
   const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
   const [deleteItemId, setDeleteItemId] = useState(null);
  const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    


  const handleAddApkClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${BASE_URL}${ENDPOINTS_API.APKFILE_GET}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data", // Optional: If you're sending form data
            },
          }
        );
        console.log("response", response);
        setApk(response.data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    setShowLoader(true);
    console.log(apkFile, apkVersion, mandatory, "lllll");
    const formDataToSend = new FormData();
    formDataToSend.append("name", name);
    formDataToSend.append("image", apkFile);
    formDataToSend.append("apkVersion", apkVersion);
    formDataToSend.append("mandatory", mandatory);
    setDisable(true)
    try {
      const response = await axios.post(
        `${BASE_URL}${ENDPOINTS_API.APKFILE}`,

        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Optional: If you're sending form data
          },
        }
      );
      if (response) {
        setShowLoader(false);
        toast.success("APK Added Successfully");
        setDisable(false)
        try {
          const response = await axios.get(
            `${BASE_URL}${ENDPOINTS_API.APKFILE_GET}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data", // Optional: If you're sending form data
              },
            }
          );
          console.log("response", response);
          setApk(response.data?.data);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
      setShowLoader(false);
      setDisable(false)
    }
    setIsModalOpen(false);
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    setApkFile(file);
  };

  const handleEditImage = (e) => {
    const file = e.target.files[0];
    setData({ ...data, image: file, url: "" });
  };

  // const handleEdiMandatory = (e)=>{
  //   const checked = e.target.checked
  //   setData({ ...data, mandatory: checked });
  // }

  const handleEdit = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const handleEditSubmit = async () => {
    const token = localStorage.getItem("token");
    setShowLoader(true);
    const formDataToSend = new FormData();
    formDataToSend.append("id", editId);
    {
      data.image
        ? formDataToSend.append("image", data.image)
        : formDataToSend.append("url", data.url);
    }
    formDataToSend.append("version", data.version);
    formDataToSend.append("name", data.name);
    formDataToSend.append("mandatory", data.is_mandatory);
    try {
      const response = await axios.put(
        `${BASE_URL}${ENDPOINTS_API.EDIT_APKFILE}`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Optional: If you're sending form data
          },
        }
      );
      if (response) {
        console.log(response);
        setIsEditModalOpen(false);
        setShowLoader(false);
      }
      toast.success("APK Edited Successfully");
      try {
        const response = await callAPI(
          "GET",
          `${BASE_URL}${ENDPOINTS_API.APKFILE}`
        );
        console.log("response", response);
        setApk(response.data);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
      setShowLoader(false);
    }
  };

  const edit = async (id) => {
    const token = localStorage.getItem("token");
    setEditId(id);
    try {
      const response = await axios.get(
        `${BASE_URL}${ENDPOINTS_API.APKFILE}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Optional: If you're sending form data
          },
        }
      );
      console.log("response", response.data.result);
      setData(response.data?.result);
      setShowFileInput(false);
    } catch (error) {
      console.log(error);
    }
    setIsEditModalOpen(true);
  };

  const handleRemoveApkFile = () => {
    setData({ ...data, url: "", image: "" });
    setShowFileInput(true);
  };

  const deleteApk = async (id) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.delete(
        `${BASE_URL}${ENDPOINTS_API.APKFILE_DELETE}/${deleteItemId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Optional: If you're sending form data
          },
        }
      );
      console.log("response", response);
              setIsDeleteModalOpen(false);

      toast.success("Deleted Successfully");
      try {
        const response = await axios.get(
          `${BASE_URL}${ENDPOINTS_API.APKFILE_GET}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data", // Optional: If you're sending form data
            },
          }
        );
        console.log("response", response);
        setApk(response.data?.data);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "apk_file.apk");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


   const handleDeleteClick = (id) => {
     setDeleteItemId(id);
     setIsDeleteModalOpen(true);
   };


  return (
    <>
      <>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "2rem",
          }}
        >
          <button className="apk_file_btnnnn" onClick={handleAddApkClick}>
            ADD APK FILE
          </button>
        </div> */}

        <div className="w-full p-5 relative">
          <table className="w-full bg-darkBlue text-[white] rounded-xl">
            <thead className="bg-[#2a325a] text-[white]">
              <tr>
                <th className="py-3 px-5">S.No.</th>
                <th className="py-3 px-5">APK file</th>
                <th className="py-3 px-5">Name</th>
                <th className="py-3 px-5">APK Version</th>
                <th className="py-3 px-5">Mandatory</th>
                <th className="py-3 px-5">Created At</th>
                <th className="py-3 px-5">Actions</th>
              </tr>
            </thead>
            <tbody>
              {apk?.map((apkItem, index) => (
                <tr key={apkItem.id} className="border-b">
                  <td className="py-3 px-5 text-center">{index + 1}</td>
                  <td className="py-3 items-center flex justify-center  px-5 cursor-pointer border-b">
                    <span onClick={() => handleDownload(apkItem?.url)}>
                      <FaDownload
                        size={20}
                        style={{ display: "inline" }}
                        color="white"
                      />
                    </span>
                  </td>
                  <td className="py-3 px-5 text-center">{apkItem?.name}</td>
                  <td className="py-3 px-5 text-center">
                    {apkItem?.apkVersion}
                  </td>
                  <td className="py-3 px-5 text-center">
                    {apkItem?.is_mandatory ? "Yes" : "No"}
                  </td>
                  <td className="py-3 px-5 text-center">
                    {apkItem?.createdAt?.slice(0, 10)}
                  </td>
                  <td className="py-3 flex justify-center items-center  px-5 ">
                    {/* <span
                      onClick={() => edit(apkItem?._id)}
                      className="mx-1 cursor-pointer"
                    >
                      <FaRegEdit size={20} color="white" />
                    </span> */}
                    <span
                      onClick={() => handleDeleteClick(apkItem?._id)}
                      className="mx-1 cursor-pointer"
                    >
                      <RiDeleteBinLine size={20} color="red" />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={handleCloseModal}>
                &times;
              </span>
              <h2>Add APK File</h2>
              <form>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>Name</label>
                  <input
                    type="text"
                    className="p-1 border border-gray-300 rounded-md my-1"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  <label>APK File</label>
                  <input
                    style={{ marginTop: "1rem", width: "15vw" }}
                    type="file"
                    onChange={(e) => handleImage(e)}
                  />
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>APK Version</label>
                  <input
                    type="text"
                    className="p-1 border border-gray-300 rounded-md my-1"
                    value={apkVersion}
                    onChange={(e) => setApkVersion(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      checked={mandatory}
                      onChange={(e) => setMandatory(e.target.checked)}
                    />
                    Mandatory
                  </label>
                </div>
                <button
                  type="button"
                  className="submt_btn"
                  onClick={handleSubmit}
                  disabled={disable}
                >
                  {disable?"Submitting" :"Submit"}
                </button>
              </form>
            </div>
          </div>
        )}

        {isEditModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={handleCloseModal}>
                &times;
              </span>
              <h2>Edit APK File</h2>
              <form>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  <label>APK File</label>
                  {data.url && !showFileInput ? (
                    <div>
                      <a
                        href={data.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data.url}
                      </a>
                      <RxCross2
                        size={20}
                        color="red"
                        onClick={handleRemoveApkFile}
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                      />
                    </div>
                  ) : (
                    <input
                      style={{ marginTop: "1rem", width: "15vw" }}
                      type="file"
                      onChange={(e) => handleEditImage(e)}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    className="p-1 border border-gray-300 rounded-md my-1"
                    value={data.name}
                    onChange={(e) => handleEdit(e)}
                  />
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label>APK Version</label>
                  <input
                    type="text"
                    name="version"
                    className="p-1 border border-gray-300 rounded-md my-1"
                    value={data.version}
                    onChange={(e) => handleEdit(e)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name="is_mandatory"
                      checked={data.is_mandatory}
                      onChange={(e) =>
                        setData({ ...data, is_mandatory: e.target.checked })
                      }
                    />
                    Mandatory
                  </label>
                </div>
                <button
                  type="button"
                  className="submt_btn"
                  onClick={handleEditSubmit}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        )}
      </>
      {/* Delete Confirmation Modal */}
      <Modal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            Are you sure you want to delete?
          </Typography>
          <Box
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={deleteApk}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default index;

import React, { useEffect, useState } from "react";
import InputComponent from "../components/inputComponent";
import TextAreaComponent from "../components/textAreaComponent";
import InputImage from "../components/inputImage";
import Button from "../components/button";
import { callAPI } from "../networks/callApi";
import { BASE_URL } from "../commonservice/commonservice";
import { ENDPOINTS_API } from "../networks/apiConstants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Announcement = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    message: "",
    image: null,
    link: "",
    // userId: [],
  });

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (file) => {
    const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
    if (file && validImageTypes.includes(file.type)) {
      setFormData((prevData) => ({
        ...prevData,
        image: file,
      }));
      toast.success("Image selected successfully");
    } else {
      toast.error(
        "Invalid file type. Please upload an image (JPEG, PNG, GIF)."
      );
    }
  };

  const handleNotification = () => {
    if (!formData.title.trim()) {
      toast.error("Title is required");
      return;
    }
    if (!formData.message.trim()) {
      toast.error("Message is required");
      return;
    }
    // if (formData.userId.length === 0) {
    //   toast.error("At least one User ID is required");
    //   return;
    // }
    // if (!formData.image) {
    //   toast.error("Image is required");
    //   return;
    // }
    const data = new FormData();
    data.append("title", formData.title);
    data.append("body", formData.message);
    data.append("image", formData.image);
    data.append("link", formData.link);
    // const userId = listDetail?.map((user) => user._id);

    // selectedUserIds.forEach((item) => data.append("userIds", item));
    // const start = new Date.now();
    callAPI(
      BASE_URL + ENDPOINTS_API.SEND_NOTIFICATION_TO_ALL,
      "POST",
      {
        headers: { "Content-type": "multipart/form-data" },
      },
      data
    )
      .then((res) => {
        // const end = new Date.now();
        // console.log("TIME NOTIFICATION", start - end);
        toast.success("Notification Sent To All Successfully");
        navigate("/announcementList");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="ml-2 mt-4 w-[80%] bg-darkBlue px-4 pt-2 pb-10 rounded-[10px]">
        <InputComponent
          lable={"Title*"}
          placeholder={"Enter your title"}
          value={formData.title}
          onChange={(value) => handleInputChange("title", value)}
        />
        <InputComponent
          lable={"Link*"}
          placeholder={"Paste link"}
          value={formData.link}
          onChange={(value) => handleInputChange("link", value)}
        />
        <TextAreaComponent
          lable={"Message*"}
          placeholder={"Enter your message"}
          value={formData.message}
          onChange={(value) => handleInputChange("message", value)}
        />
        <InputImage
          lable={"Image"}
          setFile={handleImageChange}
          setFilePreview={(preview) =>
            handleInputChange("imagePreview", preview)
          }
        />
        <div style={{ marginTop: "1rem" }}>
          <Button onClick={handleNotification} lable={"Send Notification"} />
        </div>
      </div>
    </div>
  );
};

export default Announcement;

import React, { useEffect, useState } from "react";
import GraphComponent from "./graphComponent";
import DonutComponent from "./donutComponent";
import Table from "../table";
import { useLocation } from "react-router-dom";
import { ENDPOINTS_API } from "../../networks/apiConstants";
import { BASE_URL } from "../../commonservice/commonservice";
import { callAPI } from "../../networks/callApi";
import Pagination from "../pagination";

const DashMain = () => {
  const [usersList, setUsersList] = useState([]);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [dateFilter, setDateFilter] = useState(""); // Added state for date filter
  const isToken = localStorage.getItem("token");

  useEffect(() => {
    if (isToken) {
      const fetchData = async () => {
        let query = `${
          BASE_URL + ENDPOINTS_API.GETUSERDETAILS
        }?page=${currentPage}`;

        // Modify query based on the selected date filter
        if (dateFilter) {
          query += `&date=${dateFilter}`;
        }

        try {
          const result = await callAPI(query, "GET", {
            "content-type": "application/json",
          });
          if (result) {
            setUsersList(result?.data?.users);
            setTotalPages(result?.totalPages);
          }
        } catch (error) {
          return error;
        }
      };
      fetchData();
    }
  }, [isToken, currentPage, dateFilter]);

  const detailsExpense = {
    _id: 1,
    value: "239799",
    type: "Total Expenses",
    color: "rgb(177,72,201)",
    bgColor: "rgba(177,72,201,0.2)",
    data: [650, 59, 80, 781, 56, 55, 120, 200, 100, 500, 5],
  };

  const detailsIncome = {
    _id: 1,
    value: "239799",
    type: "Total Expenses",
    color: "rgb(88,172,255)",
    bgColor: "rgba(88,172,255,0.2)",
    data: [50, 9, 80, 781, 56, 55, 20, 200, 10, 900, 5],
  };

  const detailsLoss = {
    _id: 1,
    value: "239799",
    type: "Total Expenses",
    color: "rgb(175,212,100)",
    bgColor: "rgba(175,212,100,0.2)",
    data: [60, 59, 80, 71, 56, 555, 120, 200, 10, 500, 5],
  };

  const matchCounter = {
    _id: 1,
    heading: "Match Counter",
    game: [
      { _id: 1, color: "rgb(232,84,114)", text: "BGMI", value: 100 },
      { _id: 2, color: "rgb(128,6,237)", text: "TDM", value: 50 },
      { _id: 3, color: "rgb(5,164,238)", text: "Sniper TDM", value: 80 },
      { _id: 4, color: "rgb(94,195,12)", text: "454545", value: 20 },
    ],
  };

  const columns = [
    { Header: "S.no", accessor: "sno" },
    { Header: "Name", accessor: "name" },
    { Header: "Mobile", accessor: "mobile" },
    { Header: "Email", accessor: "email" },
    { Header: "Date of Birth", accessor: "date" },
  ];

  let registrationData = [];

  usersList &&
    usersList.forEach((item, index) => {
      registrationData.push({
        sno: item.s_no,
        name: item.name,
        email: item.email,
        mobile: item.mobile,
        date: item.dob ? item.dob : "Not added",
      });
    });

  const registrationObj = {
    heading: "New Registration",
    subHeading: "Manage registered users here",
    viewAll: "View All",
    path: "/userList",
  };

  return (
    <div className="min-w-[300px] w-[100%] mx-2 ">
      <div className="overflow-x-scroll hide-scrollbar ">
        <div className="flex flex-row  w-[100%] ">
          <GraphComponent details={detailsExpense} />
          <GraphComponent details={detailsIncome} />
          <GraphComponent details={detailsLoss} />
          <DonutComponent matchCounter={matchCounter} />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
          marginRight: "1rem",
        }}
      >
        <select
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)} // Update the date filter state on change
          style={{
            borderRadius: "2px",
            width: "10vw",
            height: "4vh",
            cursor: "pointer",
          }}
        >
          <option value="">Select</option>
          <option value="today">Today</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>

      <Table
        columns={columns}
        data={registrationData}
        titleData={registrationObj}
      />

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default DashMain;

import { initializeApp } from "firebase/app";

import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyDnUFh_WeYFm48rtnXcQwCLcLp15PInAY0",
  authDomain: "battle-op-4984f.firebaseapp.com",
  projectId: "battle-op-4984f",
  storageBucket: "battle-op-4984f.appspot.com",
  messagingSenderId: "86730579502",
  appId: "1:86730579502:web:7d3519f6c31255239509bf",
  measurementId: "G-5DHFHBZ9LH",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const messaging = getMessaging(app);

const VITE_APP_VAPID_KEY =
  "BJKKrtrnUFHwe4GvoFGIO-SUMB2akrgb5jV85MdD9dOGJJ4f5SCRpCXkX65KtOlZeb-W--qoTZ3dP5Lwj5x-aXc";

export async function generateToken() {
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey: VITE_APP_VAPID_KEY,
    });

    if (token) {
      localStorage.setItem("deviceToken", token);
      return token;
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } else if (permission === "denied") {
    alert("You denied for the notification");
  }
}
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export { auth, googleProvider, facebookProvider };

/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Pagination from "../../../components/pagination";
import Table from "../../../components/table";
import { callAPI } from "../../../networks/callApi";
import { BASE_URL } from "../../../commonservice/commonservice";
import { ENDPOINTS_API } from "../../../networks/apiConstants";
import { FaTrashAlt } from "react-icons/fa"; // Import delete icon
import { MdDelete } from "react-icons/md";

const Index = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [addticketissue, setAddTicketIssue] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  const handleGetIssue = () => {
    callAPI(
      BASE_URL + ENDPOINTS_API.GET_ISSUE_LIST,
      "GET",
      {
        "Content-type": "application/json",
      },
      id
    )
      .then((res) => {
        console.log(res?.data);
        setAddTicketIssue(res?.data);
        setTotalPages(res?.data?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteIssue = (ticketIssueId) => {
    callAPI(
      BASE_URL + ENDPOINTS_API.DELETE_ISSUE_LIST + `/${ticketIssueId}`,
      "DELETE",
      {
        "Content-type": "application/json",
      }
    )
      .then((res) => {
        console.log(res.data);
        handleGetIssue(); // Refresh the list after deletion
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetIssue();
  }, [currentPage]);

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Ticket Issue Id", accessor: "ticketisssueid" },
    { Header: "Issue Name", accessor: "issuename" },
    { Header: "Created At", accessor: "createdat" },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   Cell: ({ row }) => (
    //     <button
    //       onClick={() => handleDeleteIssue(row.original.ticketisssueid)}
    //       className="text-red-500"
    //     >
    //       <MdDelete />
    //     </button>
    //   ),
    // },
  ];

  const AddTicketIssueList = [];

  addticketissue &&
    addticketissue.forEach((item, index) => {
      AddTicketIssueList.push({
        s_no: index + 1,
        ticketisssueid: item.uid,
        issuename: item.title,
        createdat: moment(item.createdAt).format("DD/MM/YYYY"),
        // action: item.action,
      });
    });

  const tableHeading = {
    heading: "Ticket Issue List",
  };

  return (
    <div>
      <div>
        <button
          onClick={() => navigate("/AddIssue")}
          type="btn"
          className="add_ticket_issue_btn"
        >
          Add Ticket Issue
        </button>
      </div>

      <Table
        columns={columns}
        data={AddTicketIssueList}
        titleData={tableHeading}
        headingSize={"text-[1rem]"}
      />

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default Index;

import React from "react";
import { Link } from "react-router-dom";

const Table = ({ titleData, columns, data, headingSize }) => {
  return (
    <div className="overflow-x-auto bg-darkBlue mt-2 rounded-[10px]">
      {titleData && (
        <div className="bg-darkBlue justify-between items-center px-4 py-2 sticky top-0 z-20">
          <div>
            <div
              className={`text-white ${
                headingSize ? headingSize : "text-[.6rem]"
              } `}
            >
              {titleData.heading}
            </div>
            <div className="text-grey text-[.4rem]">{titleData.subHeading}</div>
          </div>
          {/* {titleData.viewAll && (
            <button>
              <div className="text-white text-[.5rem] border-b-[1px] pb-[2px]">
                <Link to={titleData.path}>{titleData.viewAll}</Link>
              </div>
            </button>
          )} */}
        </div>
      )}
      <div className="overflow-x-auto bg-darkBlue rounded-[10px] max-h-[700px] w-[100%]">
        <table className="w-[100%]">
          <thead className="bg-lightBlue sticky top-0 z-10">
            <tr>
              {columns.map((column) => (
                <th
                  key={column.accessor}
                  className="py-2 px-12 text-center text-xs font-semibold text-white uppercase tracking-wider"
                >
                  {column.Header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="overflow-scroll">
            {data && data.length > 0 ? (
              data.map((row, rowIndex) => (
                <tr key={rowIndex} className="even:bg-gray-50">
                  {columns.map((column) => (
                    <td
                      key={column.accessor}
                      className={`${
                        column.accessor === "title"
                          ? "text-nowrap"
                          : "text-wrap"
                      } py-2 px-4 border-b-2 border-lightBlue text-center text-white`}
                    >
                      {Array.isArray(row[column.accessor]) ? (
                        <ul>
                          {row[column.accessor].map((subItem, idx) => (
                            <li key={idx}>{subItem}</li>
                          ))}
                        </ul>
                      ) : (
                        row[column.accessor]
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className="py-2 px-4 text-center text-white"
                >
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;

import React, { useEffect, useState } from "react";
import { getAllGame, handleDeleteGameById } from "../../networks/adminApi";
import Table from "../../components/table";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import Pagination from "../../components/pagination";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import EditGameModal from "./EditgameModal";
import { FaEdit } from "react-icons/fa";
import Loading from "../loader/Loading";
import { setAllGameList } from "../../store/reducer/usersReducer";
import { useDispatch, useSelector } from "react-redux";

const CreateEvent = ({ id, name, onEditClick }) => {
  return (
    <div className="flex justify-center">
      <Link to={`/addnewMatch/${name}/${id}`} className="text-white">
        <MdEdit />
      </Link>
    </div>
  );
};

const GetEventComponent = ({ id }) => {
  return (
    <Link to={`/getEvent/${id}`} className="text-white">
      View Events
    </Link>
  );
};

const DeleteGame = ({ id, setDeleteGame }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteById = async () => {
    const res = await handleDeleteGameById({ id });

    if (res?.success) {
      setDeleteGame(true);
      toast.success("Game deleted successfully");
      handleClose();
    } else {
      toast.error("Game delete failed");
    }
  };

  return (
    <>
      <button onClick={handleClickOpen}>
        <MdDelete />
      </button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this game?</p>
        </DialogContent>
        <DialogActions>
          <button
            style={{
              background: "#226dcf",
              color: "white",
              cursor: "pointer",
              width: "8vw",
              height: "5vh",
              borderRadius: "5px",
            }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            style={{
              background: "#226dcf",
              color: "white",
              cursor: "pointer",
              width: "8vw",
              height: "5vh",
              borderRadius: "5px",
            }}
            onClick={handleDeleteById}
            color="error"
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AllGames = () => {
  const [allGames, setAllGames] = useState([]);
  const [delteGame, setDeleteGame] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false); // For modal open state
  const [selectedGame, setSelectedGame] = useState(null); // For storing selected game id
  const [loading, SetLoading] = useState(false);

  const dispatch = useDispatch();

  const { allGameList } = useSelector((state) => state.usersList);

  useEffect(() => {
    const fetchData = async () => {
      if (allGameList?.length < 1) {
        SetLoading(true);
      }
      const startTime = Date.now();
      try {
        const res = await getAllGame(currentPage, searchTerm);
        const endTime = Date.now();

        // Calculate and log the response time
        const responseTime = endTime - startTime;
        console.log(`API Response Games: ${responseTime} ms`);

        setAllGames(res?.data?.games);
        dispatch(setAllGameList(res?.data?.games));
        setDeleteGame(false);
        setTotalPages(res?.data?.totalPages);
      } finally {
        SetLoading(false);
      }
    };
    fetchData();
  }, [delteGame, currentPage, searchTerm]);

  const filteredallGames = allGameList?.filter((item) => {
    const search = searchTerm.toLowerCase();
    return (
      String(item?.id)?.toLowerCase().includes(search) ||
      String(item?.title)?.toLowerCase().includes(search) ||
      String(item?.name)?.toLowerCase().includes(search)
    );
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEditClick = (item) => {
    setSelectedGame(item);
    setEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditModalOpen(false);
    setSelectedGame(null);
  };

  const columns = [
    { Header: "S.No", accessor: "Sno" },

    { Header: "Title", accessor: "title" },
    { Header: "Game Name", accessor: "name" },
    { Header: "Image Preview", accessor: "imgPreview" },
    { Header: "Add Event", accessor: "event" },
    { Header: "Get Event", accessor: "getEvent" },
    { Header: "Delete", accessor: "delete" },
    { Header: "Edit", accessor: "edit" },
  ];

  let registrationData = [];

  filteredallGames &&
    filteredallGames?.forEach((item) => {
      registrationData.push({
        Sno: item.s_no,
        id: item.id,
        title: item.title,
        name: item.name,
        imgPreview: (
          <div className="flex justify-center">
            <img
              src={item.image}
              className="h-10 w-10 object-contain"
              alt="img"
            />
          </div>
        ),
        event: <CreateEvent id={item.id} name={item.name} />,
        getEvent: <GetEventComponent id={item.id} />,
        delete: <DeleteGame id={item.id} setDeleteGame={setDeleteGame} />,
        edit: (
          <button
            onClick={() => handleEditClick(item)}
            style={{ color: "white", cursor: "pointer" }}
          >
            <FaEdit />
          </button>
        ),
      });
    });
  const registrationObj = {
    heading: "All Games",
  };
  return (
    <div>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search here....."
          value={searchTerm}
          onChange={handleSearch}
          style={{
            padding: "4px",
            width: "100%",
            maxWidth: "300px",
            borderRadius: "4px",
            border: "1px solid",
            marginLeft: "5px",
          }}
        />
      </div>
      <Table
        columns={columns}
        data={registrationData}
        titleData={registrationObj}
        headingSize={"text-[1rem]"}
      />

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
      {loading && <Loading />}

      {editModalOpen && (
        <EditGameModal
          open={editModalOpen}
          handleClose={handleCloseModal}
          game={selectedGame}
        />
      )}
    </div>
  );
};

export default AllGames;

import React, { useEffect, useState, useContext } from "react";
import Table from "../../components/table";
import {
  handleGetChallengeById,
  handleTransferAmount,
  handleUpdateWinner,
} from "../../networks/adminApi";
import { useParams } from "react-router-dom";
import Button from "../../components/button";
import { handleResendAmount } from "../../networks/adminApi";
import { useNavigate } from "react-router-dom";
import WinnerConfirmation from "../../components/modals/winnerConfirmation";
import InputComponent from "../../components/inputComponent";
import RefundModal from "../../components/modals/refundModal";
import { toast } from "react-toastify";
import Loading from "../loader/Loading";
import { ENDPOINTS_API } from "../../networks/apiConstants";
import { BASE_URL } from "../../commonservice/commonservice";
import { callAPI } from "../../networks/callApi";
import { SocketProvider } from "../../socket/SocketsContextProvider";
import { Modal } from "@mui/material";
import { FaEye } from "react-icons/fa";
const TransferAmountComponent = ({ id, value, onChange }) => {
  return (
    <InputComponent
      value={value}
      onChange={(e) => onChange(id, "transferAmount", e)}
      type={"number"}
    />
  );
};

const GetViewDetailsGame = () => {
  const { id } = useParams();
  const [participants, setParticipants] = useState([]);
  const [openWinnerModal, setOpenWinnerModal] = useState(false);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedParticipant, setSelectedParticipant] = useState("");
  const [priceMoney, setPriceMoney] = useState("");
  const [winnerId, setWinnerId] = useState("");
  const [inputs, setInputs] = useState({});
  const [entryFee, setEntryFee] = useState();
  const [loading, SetLoading] = useState(true);
  const [amount, setAmount] = useState();
  const [open, setOpen] = useState(false); // State for modal visibility
  const [selectedImage, setSelectedImage] = useState(null); // State to store the selected image
  const [winner, setWinner] = useState(null);
  const [matchCompleted, setMatchCompleted] = useState(false);

  const { socketRef } = useContext(SocketProvider);

  const [data, setData] = useState();
  const navigate = useNavigate();
  const gameId = useParams();

  const handleGetDetails = async () => {
    SetLoading(true);
    try {
      const res = await handleGetChallengeById({ id });
      console.log(res?.data, "DDDDDESER");
      if (res?.data?.participants) {
        setParticipants(res?.data?.participants);
        setEntryFee(res?.data?.entryFee);
        setPriceMoney(res?.data?.prizeMoney);
        setData(res?.data?.createdBy);
        setWinner(res?.data?.winner);
        setMatchCompleted(res?.data?.completed);
      }
      if (res?.data?.winner) {
        setWinnerId(res?.data?.winner);
      }
    } finally {
      SetLoading(false);
    }
  };

  useEffect(() => {
    handleGetDetails();
  }, []);

  const handleInputChange = (_id, item, value) => {
    if (value?.target?.value <= 0) {
      toast.error("Amount must be greater than 0");
      return;
    }

    setInputs((prev) => ({
      ...prev,
      [_id]: {
        ...(prev[_id] || {}),
        [item]: value || "",
      },
    }));
  };

  const transferAmount = async () => {
    if (inputs?.[selectedUserId]?.transferAmount <= 0) {
      toast.error("Transfer amount must be greater than 0");
      return;
    }

    const res = await handleTransferAmount({
      userId: selectedUserId,
      challengeId: id,
    });
    console.log(res, "Transfer Amount");
    if (res.success) {
      if (socketRef.current) {
        socketRef.current.emit("update-balance", { id: selectedUserId });
      }
      navigate(-1);
      setOpenWinnerModal(false);
    }
  };

  const handlefunc = (id) => {
    console.log(id, "chalala");
    setOpenWinnerModal(true);
    setSelectedUserId(id);
  };

  const handlerefundamount = () => {
    if (amount < 0) {
      toast.error("Refund amount must be greater than 0");
      return;
    }
    callAPI(
      BASE_URL + ENDPOINTS_API.SEND_MONEY,
      "PUT",
      {
        "Content-type": "application/json",
      },
      JSON.stringify({ amount: parseInt(amount), id: gameId.id })
    )
      .then((res) => {
        if (socketRef.current) {
          socketRef.current.emit("update-winner", {
            id: [participants[0]._id, participants[1]._id],
          });
        }
        console.log(res.data);
        toast.success("Refund Processed Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const SelectTypeTransfer = ({
    userId,
    participant_Id,
    screenshot,
    winnerId,
  }) => {
    return (
      <div className="flex felx-row">
        {/* <Button
          lable={"Refund"}
          onClick={() => [
            setOpenRefundModal(true),
            handlerefundamount(),
            setSelectedParticipant(participant_Id),
          ]}
          // disabled={
          //   screenshot === undefined
          //     ? "true"
          //     : winnerId === null || winnerId === undefined
          //     ? false
          //     : true
          // }
        /> */}
        <Button
          lable={"Transfer"}
          ml={"ml-2"}
          onClick={() => handlefunc(userId)}
          // disabled={
          //   screenshot === undefined
          //     ? "true"
          //     : winnerId === null || winnerId === undefined
          //     ? false
          //     : true
          // }
        />
      </div>
    );
  };

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Event Type", accessor: "eventtype" },
    { Header: "Entry Fee", accessor: "entryfee" },
    { Header: "Winning Amount", accessor: "winningamount" },
    { Header: "User", accessor: "user" },
    { Header: "Email", accessor: "email" },
    {
      Header: "Screenshot",
      accessor: "screenshot",
    },
    {
      Header: "View Screenshot",
      accessor: "viewScreenshot",
    },
    // { Header: "Transfer Amount", accessor: "transferAmount" },
    { Header: "User Status", accessor: "status" },
    { Header: "Admin Status", accessor: "adminStatus" },
    { Header: "Action", accessor: "refund" },
  ];

  const participantData = [];

  const handleOpenModal = (image) => {
    setSelectedImage(image); // Set the selected screenshot URL
    setOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setOpen(false); // Close the modal
    setSelectedImage(null); // Clear the selected image
  };

  participants &&
    participants.forEach((item, index) => {
      participantData.push({
        s_no: index + 1,
        user: item?.user?.name,
        eventtype: item?.user?.name,
        entryfee: entryFee,
        winningamount: priceMoney,
        email: item?.user?.email,
        screenshot: item?.screenshot ? (
          <img
            src={item?.screenshot}
            alt="Screenshot"
            style={{ height: "50px", width: "50px", marginLeft: "50px" }}
          />
        ) : (
          "No img uploaded"
        ),

        status: item?.gameStatus,
        adminStatus:
          winner && matchCompleted
            ? winner === item?._id
              ? "Won"
              : "loss"
            : "Not Updated",
        // transferAmount: (
        //   <TransferAmountComponent
        //     id={item._id
        //     value={inputs[item._id]?.transferAmount || item?.entryFee || ""}
        //     onChange={(e, item, value) => handleInputChange(e, item, value)}
        //   />
        // ),

        refund: (
          <SelectTypeTransfer
            userId={item?.user?._id}
            participant_Id={item._id}
            screenshot={item?.screenshot}
            winnerId={winnerId}
          />
        ),
        viewScreenshot: item?.screenshot ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer", // Change cursor to pointer to indicate click
            }}
            onClick={() => handleOpenModal(item?.screenshot)} // Open the modal with the screenshot
          >
            <FaEye />
          </div>
        ) : (
          "No img uploaded"
        ),
      });
    });

  return (
    <div>
      <div>
        <button
          style={{
            padding: "3px 8px",
            border: "1px solid",
            background: "#2657cf",
            color: "white",
            borderRadius: "5px",
            marginTop: "1rem",
            marginLeft: "10px",
          }}
          onClick={() => navigate("/errorchallanges")}
        >
          Error challanges
        </button>

        <div>
          <input
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            style={{ marginLeft: "10px", borderRadius: "5px" }}
            width={"w-[30%]"}
            type="number"
            placeholder="Enter Amount"
          />

          <button
            onClick={handlerefundamount}
            style={{
              width: "10vw",
              height: "4.5vh",
              background: "#2657cf",
              color: "white",
              borderRadius: "5px",
              cursor: "pointer",
              marginTop: "1rem",
              marginLeft: "1rem",
            }}
          >
            Refund
          </button>
        </div>
      </div>
      <Table
        columns={columns}
        data={participantData}
        headingSize={"text[1rem]"}
      />
      {loading && <Loading />}

      <WinnerConfirmation
        openModal={openWinnerModal}
        setOpenModal={setOpenWinnerModal}
        callfunction={() => transferAmount()}
      />
      <RefundModal
        openModal={openRefundModal}
        setOpenModal={setOpenRefundModal}
      />
      <Modal open={open} onClose={handleCloseModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",

            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "5px",
            outline: "none",
          }}
        >
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Screenshot"
              style={{ minWidth: "25vw", maxHeight: "70vh" }}
            />
          ) : (
            <p>No screenshot available</p>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default GetViewDetailsGame;

export const ENDPOINTS_API = {
  GETUSERDETAILS: "getAllUser",
  GETADMINPROFILE: "getProfile",
  GETUSERBYID: "getUserById",
  ADDGAME: "addGame",
  ALLGAME: "getAllGame",
  ADDGAMEEVENT: "addGameEvent",
  GETGAMEBYID: "getGameById",
  GETEVENTBYID: "getEventById",
  GETPARTICIPANTS: "participants/",
  GETPARTICIPANTBYID: "participant",
  EDITGAMEEVENT: "editGameEvent",
  LOGIN: "login",
  FORGOTPASSWORD: "forgotPassword",
  CHANGEPASSWORD: "changePassword",
  EDITPROFILE: "editProfile",
  UPDATEPASSWORD: "updatePassword",
  CHALLENGE: "challenge",
  ADDBANNER: "addBanner",
  GETALLBANNER: "getAllBanner",
  GETALLCHALLENGES: "challenges",
  CANCELLCHALLENGE: "cancel-challenge",
  EDITBANNER: "edit-banner",
  DELETEBANNER: "deleteBanner",
  BANNER_GET_BY_ID: "banner",
  DELETEGAME: "deleteGame",
  DELETEEVENT: "deleteEvent",
  ADDCOUPON: "add-coupon",
  COUPONS: "coupons",
  DELETECOUPON: "delete-coupon",
  EDITCOUPON: "edit-coupon",
  GETCOUPONBYID: "coupon",
  GETVIEW_DETAIL: "",
  ADDRULES: "add-rules",
  GETRULES: "rules",
  RULES_DELETE: "delete-rules",
  USER_DELETE: "delete-user",
  sdhgd: "games-top-users",
  SEND_NOTIFICATION: "send-single-notification",
  ADDROOMID: "update-event-status",
  USERCOUPONDATA: "user-coupons-data",
  TRANSACTIONLIST: "transactions",
  GAMESTOPPLAYER: "games-top-users",
  LUDOTOPPLAYERS: "ludo-top-users",
  USERNOTIFICATION: "user-notifications",
  ADMINNOTIFICATION: "admin-notifications",
  UPDATEWINNER: "update-winners",
  RETURNBALANCE: "return-balance",
  TRANSFERBALANCE: "ludo-decide-winner",
  USERBLOCKEDLIST: "deleted-user-list",
  REFERRALLIST: "referral-transaction-list",
  ALLCOUPONLIST: "all-coupon-data",
  TRANSACTION_LIST_BY_ID: "transactions",
  ADD_ISSUE: "add-issue",
  GET_ISSUE_LIST: "issues",
  DELETE_ISSUE_LIST: "delete-issue",
  GETALL_ISSUE: "tickets",
  CANCEL_EVENT: "cancel-event",
  EDIT_MATCH_RULE: "edit-rules",
  VIDEO_LINK: "add-video",
  VIDEO_LINK_LIST: "get-video",
  ADMIN_PRIZE: "prize",
  EDITMATCHRULES: "rules",
  TICKET_BY_ID: "ticket",
  SEND_MESSAGE: "send-message",
  REDEEMED_LIST: "user-coupons-data",
  REFERAL_EARNING_LIST: "referral-transaction",
  ERROR_CHALLANGES: "cancel-challenge-request",
  UPDATE_BALANCE: "update-balance",
  USEREDITAMOUNT: "user",
  WITHDRAWLS: "withdrawals",
  ABOUTUS: "addAboutUs",
  WITHDRAWLS_EDIT: "accept-reject-withdrawal",
  FAQ: "addFaq",
  GET_FAQ: "faq",
  DELETE_FAQ: "deleteFaq",
  UPDATE_FAQ: "editFaq",
  APKFILE: "apk",
  APKFILE_DELETE: "apk",
  APKFILE_GET: "apks",
  EDIT_APKFILE: "apk",
  UPDATE_GAME: "editGame",
  ROOMID_NOTIFICATION: "roomId-notification",
  SEND_MONEY: "send-money",
  SEND_NOTIFICATION_TO_ALL: "send-notification",
  NOTIFICATION_LIST: "notification",
  ABOUTUS_GET: "about-us",
  ABOUTUS_DELETE: "about-us",
  ABOUTUS_EDIT: "about-us",
  USER_TRANSACTION: "transaction-by-user",
  WINNING_HISTORY: "winning-history",
  GET_CUSTOMERSUPPORT: "contacts",
  ADD_CUSTOMERSUPPORT: "add-contact",
  EDITPLAYERDETAIL: "edit-winners",
  GETUPIID: "upi",
  EDITWINNERDATA: "update-winners-v2",
};

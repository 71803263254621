import axios from "axios";

// export const BASE_URL = "https://battleopapi.kickrtechnology.in/api/v1/admin/";
// export const BASE_URL_USER = "https://battleopapi.kickrtechnology.in/api/v1/";
export const BASE_URL = "https://api.battleop.in/api/v1/admin/";
export const BASE_URL_USER = "https://api.battleop.in/api/v1/";

export const Instance = async (method, url, header, data) => {
  //   const isInternet = await checkInternetConnection();
  // console.log({ method, url, header, data });

  try {
    const result = await axios({
      method: method,
      url: url,
      headers: header,
      data: data,
    });

    return result;
  } catch (e) {
    console.log(e);
    return e;
  }
};

/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, Button as MUIButton } from "@mui/material";
import LinkComponent from "../components/linkComponent";
import Table from "../components/table";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { handleGetRules, handleGetRulesDelete } from "../networks/adminApi";
import Pagination from "../components/pagination";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ActionComponent = ({ id, handleOpenDeleteModal }) => {
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/editMatchRule/${id}`);
  };

  return (
    <div className="flex justify-center">
      <FaEdit className="mr-2  cursor-pointer" onClick={handleEdit} />{" "}
      <button onClick={() => handleOpenDeleteModal(id)}>
        <MdDelete />
      </button>
    </div>
  );
};

const MatchRules = () => {
  const [gameRulesList, setGameRulesList] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRuleId, setSelectedRuleId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const res = await handleGetRules(currentPage);

      if (res?.success) {
        setGameRulesList(res?.data?.rules);
        setDeleted(false);
        setTotalPages(res?.data?.totalPages);
      }
    };
    fetchData();
  }, [deleted, currentPage]);

  useEffect(() => {
    const existingRules = JSON.parse(localStorage.getItem("MatchRules"));
    setGameRulesList(existingRules);
  }, []);

  const handleOpenDeleteModal = (id) => {
    setSelectedRuleId(id);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedRuleId(null);
  };

  const handleDeleteConfirm = async () => {
    if (selectedRuleId) {
      const res = await handleGetRulesDelete({ id: selectedRuleId });
      if (res?.success) {
        setDeleted(true);
        toast.success("MatchRule Deleted Successfully");
      }
      handleCloseDeleteModal();
    }
  };

  const columns = [
    { Header: "S.No", accessor: "Sno" },
    { Header: "Game Type", accessor: "gametype" },
    { Header: "Game Name", accessor: "name" },
    { Header: "Heading", accessor: "matchtitle" },
    { Header: "Match Rules", accessor: "matchRules" },
    { Header: "Action", accessor: "action" },
  ];

  const matchRulesData = [];

  gameRulesList &&
    gameRulesList.map((item) => {
      matchRulesData.push({
        Sno: item.s_no,
        name: item.name,
        matchRules: item.rules,
        gametype: item.gameType,
        matchtitle: item?.title,
        action: (
          <ActionComponent
            id={item._id}
            handleOpenDeleteModal={handleOpenDeleteModal}
          />
        ),
      });
    });

  console.log({ matchRulesData });

  return (
    <div>
      <LinkComponent path={"/addMatchRules"} lable={"Add Match Rules"} />
      <Table columns={columns} data={matchRulesData} />

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />

      {/* Delete Confirmation Modal */}
      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-confirmation-title"
        aria-describedby="delete-confirmation-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="delete-confirmation-title"
            variant="h6"
            component="h2"
          >
            Are you sure you want to delete this match rule?
          </Typography>
          <Box mt={2} display="flex" gap={"10px"} justifyContent={"flex-end"}>
            <MUIButton
              variant="contained"
              color="primary"
              onClick={handleDeleteConfirm}
            >
              Yes
            </MUIButton>
            <MUIButton
              variant="contained"
              color="secondary"
              onClick={handleCloseDeleteModal}
            >
              No
            </MUIButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default MatchRules;

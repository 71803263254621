import React, { useEffect, useState } from "react";
import "./style.css";
import { ENDPOINTS_API } from "../../networks/apiConstants";
import { BASE_URL } from "../../commonservice/commonservice";
import { callAPI } from "../../networks/callApi";
import { toast } from "react-toastify";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Pagination from "../../components/pagination";
import Table from "../../components/table";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import EditModal from "./editmodalcontact";
import { Box, Button, Modal, Typography } from "@mui/material";

const Index = () => {
  const [selectedOption, setSelectedOption] = useState("select");
  const [content, setContent] = useState("");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [aboutus, setAboutus] = useState([]);
  // State for Edit Modal
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const { id } = useParams();

  const handleContactUs = () => {
    if (selectedOption === "select" || !content) {
      toast.error("Please select an option and enter content.");
      return;
    }

    callAPI(
      BASE_URL + ENDPOINTS_API.ABOUTUS,
      "POST",
      {
        "Content-type": "application/json",
      },
      JSON.stringify({
        type: selectedOption,
        content: content,
      })
    )
      .then((res) => {
        console.log(res);
        toast.success("Content Added Successfully");
        setSelectedOption("");
        setContent("");
        handleGetContactUs();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleGetContactUs = () => {
    callAPI(BASE_URL + ENDPOINTS_API.ABOUTUS_GET, "GET", {
      "Content-type": "application/json",
    })
      .then((res) => {
        console.log(res,"ABOUTUS");
        setAboutus(res?.data);
        setTotalPages(res?.data?.totalPages)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetContactUs();
  }, []);

  const handleDeleteContactUs = (id) => {
    callAPI(
      `${BASE_URL + ENDPOINTS_API.ABOUTUS_DELETE}/${deleteItemId}`,
      "DELETE",
      {
        "Content-type": "application/json",
      }
    )
      .then((res) => {
        console.log(res);
        toast.success("Deleted Successfully");
        // setAboutus(res.data);
        setIsDeleteModalOpen(false);
        handleGetContactUs();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Open Edit Modal with selected item data
  const handleEditClick = (item) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  const handleDeleteClick = (id) => {
    setDeleteItemId(id);
    setIsDeleteModalOpen(true);
  };

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Type", accessor: "type" },

    { Header: "Content", accessor: "content" },
    { Header: "Action", accessor: "action" },
  ];

  const AboutusListdata = [];

  const typeData ={aboutus:"About us",privacypolicy:"Privacy Policy","terms&condition":"Terms & Condition"}

  aboutus &&
    aboutus?.forEach((item, index) => {
      AboutusListdata?.push({
        s_no: index + 1,
        content: item.content?.slice(0, 100) + ".....",
        type: typeData[item.type],
        action: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <FaEdit onClick={() => handleEditClick(item)} />
            <MdDelete onClick={() => handleDeleteClick(item._id)} />
          </div>
        ),
      });
    });

  const tableHeading = {
    heading: "LIST",
  };
  return (
    <>
      <div>
        <select
          style={{
            marginTop: "2rem",
            marginLeft: "1rem",
            borderRadius: "5px",
            width: "40vw",
            height: "5vh",
          }}
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <option value="select">Select</option>
          <option value="terms&condition">Terms & Condition</option>
          <option value="aboutus">About Us</option>
          <option value="privacypolicy">Privacy Policy</option>
        </select>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          <label style={{ color: "white", marginLeft: "1rem" }}>
            Add Content
          </label>
          <textarea
            style={{
              marginTop: "10px",
              width: "40vw",
              height: "30vh",
              borderRadius: "5px",
              marginLeft: "1rem",
            }}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Enter content here"
          />
        </div>

        <div>
          <button
            onClick={handleContactUs}
            style={{
              width: "10vw",
              height: "5vh",
              cursor: "pointer",
              border: "1px solid",
              marginTop: "1rem",
              marginLeft: "1rem",
              background: "#2657cf",
              color: "white",
              borderRadius: "5px",
            }}
          >
            Add Content
          </button>
        </div>
        <div>
          <Table
            columns={columns}
            data={AboutusListdata}
            titleData={tableHeading}
            headingSize={"text-[1rem]"}
          />
          {/* <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          /> */}
        </div>
        {/* Delete Confirmation Modal */}
        <Modal
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              textAlign: "center",
            }}
          >
            <Typography variant="h6" component="h2">
              Are you sure you want to delete?
            </Typography>
            <Box
              sx={{
                mt: 3,
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteContactUs}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                No
              </Button>
            </Box>
          </Box>
        </Modal>
        {/* Edit Modal */}
        <EditModal
          open={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          item={selectedItem}
          handleGetContactUs={handleGetContactUs}
        />
      </div>
    </>
  );
};

export default Index;

import React, { createContext, useState, useContext, useEffect } from "react";
import { getUsersList } from "../networks/adminApi";

const AuthContext = createContext();

export const useContextValue = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [noOfuser, setNoOfUser] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const res = await getUsersList();
      if (res?.success) {
        setNoOfUser(res?.data?.length);
      }
    };
    if (localStorage.getItem("token")) {
      // fetchData();
    }
  }, []);

  return (
    <AuthContext.Provider value={{ noOfuser }}>{children}</AuthContext.Provider>
  );
};

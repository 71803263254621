import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  handleMatchWinningEsportList,
  handleMatchWinningList,
} from "../networks/adminApi";
import Pagination from "../components/pagination";
import Table from "../components/table";
import Button from "../components/button";
const MatchWinningList = () => {
  const [type, setType] = useState("ludo");
  const [winningData, setWinningData] = useState([]);
  const [esportData, setEsportData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const { id } = useParams();

  const fetchWinningHistory = async () => {
    const res = await handleMatchWinningList({ id, type });
    console.log(res, "LUDO");
    setWinningData(res?.data?.winners);
    setTotalPages(res?.data?.totalPages);
  };

  const fetchesportWinningList = async () => {
    const res = await handleMatchWinningEsportList({ id, type });
    console.log(res, "Esport");
    setEsportData(res?.data?.winners);
    setTotalPages(res?.data?.totalPages);
  };

  useEffect(() => {
    if (type === "ludo") {
      fetchWinningHistory();
    } else {
      fetchesportWinningList();
    }
  }, [type]);

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Name", accessor: "name" },
    { Header: "Mobile No.", accessor: "mobileno" },
    { Header: "Room Id", accessor: "roomId" },
    { Header: "Entry Fee", accessor: "entryfee" },
  ];

  const tableHeading = {
    heading: "Winning Data",
  };

  const winningDataList = [];
  winningData &&
    winningData?.forEach((item, index) => {
      winningDataList.push({
        _id: item._id,
        s_no: index + 1,
        name: item?.user?.name,
        mobileno: item?.user?.mobile,
        roomId: item?.challenge?.appRoomId,
        entryfee: item?.challenge?.entryFee,
      });
    });

  const columns1 = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "User Name", accessor: "name" },
    { Header: "Joined by", accessor: "joinedby" },
    { Header: "Winning Amount", accessor: "winnings" },
    { Header: "Game Name", accessor: "gameName" },
    { Header: "Game Title", accessor: "gameTitle" },
    { Header: "Match Title", accessor: "gameTitle" },
    { Header: "Entry Fee", accessor: "entryfee" },
  ];

  const winningListEsport = [];

  esportData &&
    esportData?.forEach((item, index) => {
      winningListEsport.push({
        _id: item?._id,
        s_no: index + 1,
        name: item?.username,
        joinedby: item?.joinedByUserId?.name,
        gameName: item?.gameId?.name,
        gameTitle: item?.gameId?.title,
        gameTitle: item?.gameEventId?.title,
        winnings: item?.winningAmount,
        entryfee: item?.gameEventId?.entryFee,
      });
    });
  return (
    <div className="mx-4 pb-4">
      <div className="flex flex-row">
        <Button lable={"Ludo"} onClick={() => setType("ludo")} />
        <Button
          lable={"Esport"}
          ml={"ml-4"}
          onClick={() => setType("esport")}
        />
      </div>
      {type === "ludo" ? (
        <Table
          columns={columns}
          data={winningDataList}
          titleData={tableHeading}
          headingSize={"text-[1rem]"}
        />
      ) : (
        <Table
          columns={columns1}
          data={winningListEsport}
          titleData={tableHeading}
          headingSize={"text-[1rem]"}
        />
      )}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default MatchWinningList;

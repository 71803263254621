import React, { useEffect, useState } from "react";
import { handleAllUserCouponData } from "../networks/adminApi";
import Table from "../components/table";
import Pagination from "../components/pagination";

const TimeFilter = ({ time }) => {
  const date = new Date(time);

  const formattedDate = date.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return <div>{formattedDate}</div>;
};
const AllCouponList = () => {
  const [couponList, setCouponList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const fetchAllUserCoupon = async () => {
    const res = await handleAllUserCouponData(currentPage);

    if (res?.data) {
      setCouponList(res?.data);
      setTotalPages(res?.totalPages);
    }
  };

  useEffect(() => {
    fetchAllUserCoupon();
  }, [currentPage]);

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Amount", accessor: "amount" },
    { Header: "Coupon Id", accessor: "couponId" },
    { Header: "Redeemed", accessor: "redeemed" },
    { Header: "Time", accessor: "time" },
  ];

  const couponData = [];

  couponList &&
    couponList.map((item, index) => {
      couponData.push({
        id: item._id,
        s_no: index + 1,
        amount: item.amount,
        redeemed: item.isRedeemed ? "Redeemed" : "Not Redeemed",
        time: <TimeFilter time={item.createdAt} />,
        couponId: item.couponId,
      });
    });

  const tableHeading = {
    heading: "Coupon Heading",
  };

  return (
    <div>
      <div className="mx-4 pb-4 pt-4">
        <Table
          columns={columns}
          data={couponData}
          titleData={tableHeading}
          headingSize={"text-[1rem]"}
        />
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default AllCouponList;
